import React from 'react';
import { Box, Text } from '@nimbus-ds/components';
import {
  formatCuit,
  getCuitOrCuilLabel,
  useTranslationWithPrefix,
} from 'commons/utils';
import TextByCountry from '../TextByCountry/TextByCountry';
import BankAccountInfoSkeleton from './BankAccountInfoSkeleton';

interface InterfaceBankAccountInfo {
  bankAccountInfo: {
    fiscalNumber: string;
    beneficiary: string;
    accountNumber: string;
    bank: string;
  };
}

function BankAccountInfo({
  bankAccountInfo,
}: InterfaceBankAccountInfo): JSX.Element {
  const { t } = useTranslationWithPrefix('bankAccountRegistration');

  const document = bankAccountInfo.fiscalNumber;

  return (
    <Box
      backgroundColor="neutral-surfaceDisabled"
      padding="2"
      borderRadius="1"
      display="flex"
      flexDirection="column"
      gap="1"
    >
      <TextByCountry
        AR={`${getCuitOrCuilLabel(document)}: ${formatCuit(document)}`}
        BR={`${getCuitOrCuilLabel(document)}: ${formatCuit(document)}`}
        MX={`${t('rfc')}: ${document}`}
      />
      <Text>
        {t('beneficiaryName')}: {bankAccountInfo.beneficiary}
      </Text>
      <Text>
        {t('accountNumber')}: {bankAccountInfo.accountNumber}
      </Text>
      <Text>
        {t('bank')}: {bankAccountInfo.bank}
      </Text>
    </Box>
  );
}

BankAccountInfo.Skeleton = BankAccountInfoSkeleton;
export default BankAccountInfo;
