import { track } from '@amplitude/analytics-browser';
import { RecipientType } from 'domains/Brazil/commons/types/RecipientType.enum';
import { DocumentType } from './types/DocumentType.enum';

const prefix = 'nuvempago_identity_validation';

const adaptPersonType = (recipientType: RecipientType) =>
  recipientType === RecipientType.Company ? 'pj' : 'pf';

const trackNavigation = (recipientType: RecipientType, activeStep: string) => {
  if (!recipientType || !activeStep) {
    return;
  }

  const personType = adaptPersonType(recipientType);
  const action = `${prefix}_${personType}_${activeStep}`;

  track(action);
};

const trackUpload = (
  recipientType: RecipientType,
  documentType: DocumentType,
  isDocumentResend = false,
) => {
  if (!recipientType || !documentType) {
    return;
  }

  const personType = adaptPersonType(recipientType);
  const document = documentType.toLowerCase();

  const action =
    `${prefix}_${personType}_upload_${document}`.toLocaleLowerCase();

  track(action, {
    isDocumentResend,
  });
};

const trackEvent = (event: string) => {
  const tag = `${prefix}_${event}`;
  track(tag);
};

export { trackNavigation, trackUpload, trackEvent };
