import { useMexicanBanks } from 'commons/utils/mexicanBankAccount';
import { BankAccountValidator } from '../BankAccountValidator.interface';

export class CLABEValidator implements BankAccountValidator {
  private readonly requiredLength = 18;
  private readonly mexicanBanks = useMexicanBanks();

  formatFiscalNumber(fiscalNumber: string): string {
    return fiscalNumber;
  }

  isValid(accountNumber: string): boolean {
    // CLABE validation logic:
    // 1. Must be 18 digits
    // 2. First 3 digits are bank code
    // 3. Next 3 digits are branch code
    // 4. Next 11 digits are account number
    // 5. Last digit is check digit
    if (accountNumber?.length !== this.getRequiredLength()) {
      return false;
    }

    // Check if it's all digits
    if (!/^\d+$/.test(accountNumber)) {
      return false;
    }

    const checksum = this.calculateChecksum(accountNumber);
    return checksum === parseInt(accountNumber.charAt(17), 10);
  }

  getAssociatedBank(accountNumber: string): string {
    if (!this.isValid(accountNumber)) return '';

    // Get bank based on the first 3 digits
    const bankCode = accountNumber.substring(0, 3);
    return this.getMexicanBank(bankCode);
  }

  getRequiredLength(): number {
    return this.requiredLength;
  }

  private calculateChecksum(accountNumber: string): number {
    // Implement CLABE checksum algorithm:
    // 1. Multiply each digit by its corresponding weight
    // 2. Sum the results
    // 3. Take modulo 10
    // 4. Subtract from 10 (if not 10)

    // Weights for each position
    const weights = [3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7];

    let sum = 0;
    for (let i = 0; i < 17; i++) {
      const digit = parseInt(accountNumber.charAt(i), 10);
      const weight = weights[i];
      let product = digit * weight;

      // If product is >= 10, take last digit
      if (product >= 10) {
        product = product % 10;
      }

      sum += product;
    }

    // Get the check digit
    return (10 - (sum % 10)) % 10;
  }
  private getMexicanBank(bankCode: string): string {
    return this.mexicanBanks.getByBankCode(bankCode);
  }
}

export const clabeValidator = new CLABEValidator();
