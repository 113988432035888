import { NotificationStyleType } from 'commons/types';

export enum NotificationTypes {
  AntiFraudStoreDisabled = 'anti-fraud.store-disabled',
  KycStoreWarning = 'kyc.store-warning',
  KycStoreInfo = 'kyc.store-info',
  KycStoreBlocked = 'kyc.store-blocked',
  KycDeniedNewCohort = 'kyc-denied-new-cohort',
  KycDeniedMerchantBase = 'kyc-denied-merchant-base',
  KycDeniedByReason = 'kyc-denied-by-reason',
  ManualEnrichment = 'manual-enrichment',
  RefundError = 'refund.error',
  NoType = 'without-type',
  WithdrawalRefused = 'withdrawal-refused',
  WithdrawalCanceled = 'withdrawal-canceled',
  WithdrawalCreated = 'withdrawal-created',
  WithdrawalProcessing = 'withdrawal-processing',
  WithdrawBlockedByRisk = 'withdraw-blocked-by-risk',
  WithdrawBlockedByLending = 'withdraw-blocked-by-lending',
  WithdrawUnblockedByLending = 'withdraw-unblocked-by-lending',
  LendingInstallmentWillOverdue = 'lending-installment-will-overdue',
  LendingInstallmentDueToday = 'lending-installment-due-today',
  LendingInstallmentOverdue = 'lending-installment-overdue',
  LivenessPending = 'liveness-pending',
  LivenessInAnalysis = 'liveness-in-analysis',
  LivenessApproved = 'liveness-approved',
  LivenessPartiallyDenied = 'liveness-partially-denied',
  PolRemediationPending = 'pol-remediation.pending',
  PolRemediationPendingModal = 'pol-remediation.pending-modal',
  WithdrawsTemporarilyBlocked = 'withdraws.temporarily-blocked',
  D1Enabled = 'd1.enabled',
  BankingIdentityValidationRequired = 'ft-bk-acc-k8s-checking-identity-validation-required',
  BankingIdentityValidationInProgress = 'ft-bk-acc-k8s-checking-identity-validation-in-progress',
  BankingIdentityValidated = 'ft-bk-acc-k8s-checking-identity-validated',
  BankingAccountSuspended = 'ft-bk-acc-k8s-checking-account-suspended',
  BankingDocumentSuspended = 'ft-bk-acc-k8s-checking-document-suspended',
}

export type NotificationMetadataResponse = {
  titleMetadata?: Record<string, any>;
  messageMetadata?: Record<string, any>;
};

export enum NotificationPresentationType {
  Alert = 'alert',
  Modal = 'modal',
}

export enum NotificationLinkPresentationType {
  Anchor = 'anchor',
  Button = 'button',
}

export type GetNotificationsResponse = {
  id: string;
  title: string;
  message: string;
  titleCode?: string;
  messageCode?: string;
  style: NotificationStyleType;
  linkName?: string;
  linkUrl?: string;
  linkTag?: string;
  linkPresentationType?: NotificationLinkPresentationType;
  type?: NotificationTypes;
  metadata?: NotificationMetadataResponse;
  deletable?: boolean;
  presentationType?: NotificationPresentationType;
  hasInternalUrl?: boolean;
  deleteOnClose?: boolean;
  deleteOnRefresh?: boolean;
  showDismissButton?: boolean;
};
