import { TFunction } from 'react-i18next';
import {
  ChargebackReason,
  ChargebackStatus,
  EventStatusType,
  EventType,
  MoneyObjectInterface,
  PaymentMethodsType,
  TransactionStatusType,
} from 'commons/types';
import { IStoreInfo } from 'redux/slices/types';
import { formatCurrency } from '.';

export const getTransactionDescription = (
  t: TFunction<string, undefined>,
  storeInfo: IStoreInfo,
  status: TransactionStatusType,
  paymentMethod: PaymentMethodsType,
  partiallyRefundedAmount?: MoneyObjectInterface,
  reason?: string,
) => {
  switch (status) {
    case 'fraud_analysis_approved':
      return '';

    case 'authorized':
    case 'pending':
      return t(`transactionDetails.paymentHistory.pending.${paymentMethod}`);

    case 'paid':
      return t('transactionDetails.paymentHistory.paid');

    case 'partially_refunded':
      return t('transactionDetails.paymentHistory.partially_refunded', {
        amount: `${formatCurrency(partiallyRefundedAmount!, storeInfo)}`,
      });

    case 'refunded':
      return t('transactionDetails.paymentHistory.refunded');

    case 'chargebacked':
    case 'chargeback_dispute':
      return t('transactionDetails.paymentHistory.chargebacked');

    case 'failed':
      return reason ? t(`transactionDetails.paymentHistory.${reason}`) : '';

    case 'expired':
      return t(`transactionDetails.paymentHistory.expired.${paymentMethod}`);

    default:
      return;
  }
};

export const getEventDescription = (
  type: EventType,
  status: EventStatusType,
  t: TFunction<string, undefined>,
  storeInfo: IStoreInfo,
  partiallyRefundedAmount?: MoneyObjectInterface,
  chargebackStatus?: ChargebackStatus,
  chargebackReason?: ChargebackReason,
): string => {
  if (type === 'chargeback_dispute') {
    return t(
      `transactionDetails.paymentHistory.chargebackDispute.${status}.description`,
    );
  }
  if (type === 'chargeback') {
    if (status === 'pending') {
      return chargebackReason === ChargebackReason.Fraud
        ? t(
            'transactionDetails.paymentHistory.chargeback.pendingFraud.description',
          )
        : t(
            'transactionDetails.paymentHistory.chargeback.pendingDispute.description',
          );
    } else if (status === 'failure') {
      return t(
        `transactionDetails.paymentHistory.chargeback.failure.description`,
      );
    } else {
      switch (chargebackStatus) {
        case ChargebackStatus.ResolvedWithoutDispute:
          return t(
            'transactionDetails.paymentHistory.chargeback.resolvedWithoutDispute.description',
          );

        case ChargebackStatus.ResolvedMerchant:
        case ChargebackStatus.ResolvedInsurance:
          return t(
            'transactionDetails.paymentHistory.chargeback.resolvedMerchant.description',
          );

        case ChargebackStatus.ResolvedCustomer:
          return t(
            'transactionDetails.paymentHistory.chargeback.resolvedCustomer.description',
          );

        default:
          return '';
      }
    }
  }

  const parsedPartialRefundedAmount = partiallyRefundedAmount
    ? formatCurrency(partiallyRefundedAmount, storeInfo)
    : 0;
  const descriptions = {
    paid_pending: '',
    paid_failure: '',
    paid_success: t('transactionDetails.paymentHistory.paid'),
    partially_refunded_pending: t(
      'transactionDetails.paymentHistory.partially_refunded.pending.description',
      { amount: `${parsedPartialRefundedAmount}` },
    ),
    partially_refunded_failure: t(
      'transactionDetails.paymentHistory.partially_refunded.failure.description',
      { amount: `${parsedPartialRefundedAmount}` },
    ),
    partially_refunded_success: t(
      'transactionDetails.paymentHistory.partially_refunded.success.description',
      { amount: `${parsedPartialRefundedAmount}` },
    ),
    refunded_pending: t(
      'transactionDetails.paymentHistory.refunded.pending.description',
    ),
    refunded_failure: t(
      'transactionDetails.paymentHistory.refunded.failure.description',
    ),
    refunded_success: t(
      'transactionDetails.paymentHistory.refunded.success.description',
    ),
    expiration_success: t(
      'transactionDetails.paymentHistory.expired.success.description',
    ),
    expiration_pending: '',
    expiration_failure: '',
  };
  return descriptions[`${type}_${status}`];
};
