import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box, Icon, Link, Text } from '@nimbus-ds/components';
import { EditIcon, PlusCircleIcon } from '@nimbus-ds/icons';
import { ROUTES } from 'App/Routes/constants';
import { useTranslationWithPrefix } from 'commons/utils';

interface InterfaceBankAccount {
  isRegistered: boolean;
  bankName: string;
  beneficiaryName: string;
  beneficiaryAccountNumber: string;
}

const BankAccount = ({
  isRegistered,
  bankName,
  beneficiaryName,
  beneficiaryAccountNumber,
}: InterfaceBankAccount): JSX.Element => {
  const { t } = useTranslationWithPrefix('configuration.personalData');
  const history = useHistory();

  const redirectToBankAccountRegistration = () => {
    history.push(ROUTES.BANK_ACCOUNT_CONFIG);
  };

  const handleEditClick = () => {
    track('pago-nube-editar-cbu');
    redirectToBankAccountRegistration();
  };

  const AccountEmpty = (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Text>{t('bankAccountTitle')}</Text>
      <Link
        textDecoration="none"
        appearance="primary"
        onClick={redirectToBankAccountRegistration}
      >
        <Icon color="currentColor" source={<PlusCircleIcon />} />
        {t('registration')}
      </Link>
    </Box>
  );

  const AccountInfo = (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column" gap="2" width="100%">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Text color="neutral-textHigh">{t('bankAccountTitle')}</Text>
          <Link
            textDecoration="none"
            appearance="primary"
            onClick={handleEditClick}
          >
            <Icon color="currentColor" source={<EditIcon />} />
            {t('edit')}
          </Link>
        </Box>
        <Box display="flex" flexDirection="column" gap="1">
          <Text>
            {t('beneficiary')}: {beneficiaryName}
          </Text>
          <Text>
            {t('accountNumber')}: {beneficiaryAccountNumber}
          </Text>
          <Text>
            {t('bank')}: {bankName}
          </Text>
        </Box>
      </Box>
    </Box>
  );

  return isRegistered ? AccountInfo : AccountEmpty;
};

export default BankAccount;
