import { formatCuit } from 'commons/utils';
import * as cbuUtils from 'commons/utils/cbu';
import { getAssociatedBank } from '../../BankNameRetriever/BankNameRetriever';
import { BankAccountValidator } from '../BankAccountValidator.interface';

export class CBUValidator implements BankAccountValidator {
  private readonly requiredLength = 22;

  formatFiscalNumber(fiscalNumber: string): string {
    return formatCuit(fiscalNumber);
  }

  isValid(accountNumber: string): boolean {
    return cbuUtils.isValid(accountNumber);
  }

  getAssociatedBank(accountNumber: string): string {
    return getAssociatedBank(accountNumber);
  }

  getRequiredLength(): number {
    return this.requiredLength;
  }
}

export const cbuValidator = new CBUValidator();
