import React from 'react';
import { StepNavigatorContext } from '../StepNavigator';

interface StepProps {
  children: React.ReactElement;
}

function Step({ children }: StepProps) {
  const navigatorContext = React.useContext(StepNavigatorContext);
  if (!navigatorContext) return null;

  const { previousStep, nextStep } = navigatorContext;

  return React.cloneElement(children, {
    previousStep,
    nextStep,
  });
}

export { Step };
