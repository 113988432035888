import React from 'react';
import { track } from '@amplitude/analytics-browser';
import {
  Box,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverPlacement,
  Text,
} from '@nimbus-ds/components';
import { CloseIcon } from '@nimbus-ds/icons';
import { Country } from 'App/i18n/enums';
import { useFeatureDiscovery } from 'commons/hooks';
import { useCountry } from 'commons/hooks/useCountry';
import { FeatureName } from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';

interface FeatureDiscoveryPopoverProps {
  featureName: FeatureName;
  children: JSX.Element;
  title?: string;
  description?: string;
  linkText?: string;
  onLinkClick?: React.MouseEventHandler<HTMLElement>;
  onDismissCallback?: () => void;
  position?: PopoverPlacement;
  zIndex?: React.ComponentProps<typeof Popover>['zIndex'];
  amplitudeTag?: string;
  onlyForCountries?: Country[];
  maxWidth?: string;
}

export default function FeatureDiscoveryPopover({
  children,
  featureName,
  title,
  description,
  linkText,
  onLinkClick,
  onDismissCallback,
  position,
  zIndex,
  amplitudeTag,
  onlyForCountries,
  maxWidth = '300px',
}: Readonly<FeatureDiscoveryPopoverProps>) {
  const featureDiscovery = useFeatureDiscovery(featureName);
  const { storeCountry } = useCountry();

  const handleOnDismiss = () => {
    featureDiscovery.markAsDiscovered.mutate();
    onDismissCallback?.();
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (amplitudeTag) track(amplitudeTag);
    featureDiscovery.markAsDiscovered.mutate();
    onLinkClick?.(event);
  };

  const popoverContent = (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Text color="neutral-surface" fontSize="base" fontWeight="bold">
          {title}
        </Text>
        <Box position="absolute" top="4px" right="4px">
          <IconButton
            onClick={handleOnDismiss}
            size="2.75rem"
            source={<Icon color="neutral-background" source={<CloseIcon />} />}
            borderColor="transparent"
            backgroundColor="transparent"
          />
        </Box>
      </Box>
      <Text color="neutral-surface" fontSize="base">
        {description}
      </Text>
      {linkText && onLinkClick ? (
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Link onClick={handleOnClick} appearance="neutral-background">
            {linkText}
          </Link>
        </Box>
      ) : null}
    </Box>
  );

  const countryAllowed =
    !onlyForCountries || onlyForCountries.includes(storeCountry);

  const shouldRenderPopover = !featureDiscovery.isDiscovered && countryAllowed;

  return (
    <Popover
      content={popoverContent}
      backgroundColor="primary-interactiveHover"
      visible={shouldRenderPopover}
      position={position ?? 'top-end'}
      maxWidth={maxWidth}
      zIndex={zIndex}
    >
      {children}
    </Popover>
  );
}
