import React from 'react';
import { Box, Button, Modal, Spinner, Text } from '@nimbus-ds/components';
import { BankAccountInfo } from 'commons/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface ConfirmationModalProps {
  bankAccountInfo: {
    fiscalNumber: string;
    beneficiary: string;
    accountNumber: string;
    bank: string;
  };
  buttonDisabled: boolean;
  open: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}

export default function ConfirmationModal({
  bankAccountInfo,
  buttonDisabled,
  open,
  onDismiss,
  onConfirm,
}: ConfirmationModalProps) {
  const { t } = useTranslationWithPrefix('bankAccountRegistration');

  return (
    <Modal title={t('confirmModal.title')} open={open} onDismiss={onDismiss}>
      <Modal.Header title={t('confirmModal.title')} />
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="6">
        <Text> {t('confirmModal.text')}</Text>
        <BankAccountInfo bankAccountInfo={bankAccountInfo} />
        <Box display="flex" justifyContent="flex-end" gap="4">
          <Button
            onClick={onDismiss}
            appearance="neutral"
            disabled={buttonDisabled}
          >
            {t('confirmModal.cancel')}
          </Button>
          <Button
            onClick={onConfirm}
            appearance="primary"
            disabled={buttonDisabled}
          >
            {buttonDisabled && <Spinner size="small" />}
            {t('confirmModal.registerAccount')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
