import React, { MouseEvent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Link, Text } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useStoreURL } from 'commons/hooks';
import { OrderType } from 'commons/types/OrderType.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import { TypeTagV2 } from 'domains/Brazil/commons/components/StatusTagV2';

type DashboardLinkField = {
  orderNumber?: string;
  type?: OrderType;
  orderId: string;
  status: string;
};

export const amplitudeTagStatusMap = new Map<string, string>([
  [TypeTagV2.Paid, 'approved'],
  [TypeTagV2.Refunded, 'refunded'],
  [TypeTagV2.PartiallyRefunded, 'partial_refunded'],
  [TypeTagV2.Contested, 'chargeback'],
  [TypeTagV2.Failed, 'issuer_bank_rejected'],
  [TypeTagV2.RejectedBySecurity, 'security_rejected'],
  [TypeTagV2.Pending, 'pending'],
  [TypeTagV2.Expired, 'expired'],
  [TypeTagV2.Authorized, 'inprocess'],
]);

function DashboardLinkField({
  orderNumber,
  type = OrderType.Checkout,
  orderId,
  status,
}: DashboardLinkField): JSX.Element {
  const { t } = useTranslationWithPrefix('dashboard.components.dashboardLink');
  const history = useHistory();
  const { buildAbsoluteStoreURL } = useStoreURL();

  const amplitudeTag = useCallback(() => {
    if (type === OrderType.Checkout) {
      return `nuvempago_dashboard_${amplitudeTagStatusMap.get(
        status,
      )}_transaction_details_click`;
    } else {
      return `nuvempago_dashboard_${amplitudeTagStatusMap.get(
        status,
      )}_payment_link_detail_click`;
    }
  }, [status, type]);

  const detailsPath = useMemo(() => {
    return type === OrderType.Checkout
      ? `/admin/v2/nuvempago#${ROUTES.TRANSACTION_DETAILS}/${orderId}`
      : `/admin/v2/nuvempago#${ROUTES.PAYMENT_LINK_DETAILS}/${orderId}`;
  }, [orderId, type]);

  const goToTransactionDetails = useCallback(
    (orderId) => history.push(`${ROUTES.TRANSACTION_DETAILS}/${orderId}?v=2`),
    [history],
  );

  const goToPaymentLinkDetails = useCallback(
    (orderId) => history.push(`${ROUTES.PAYMENT_LINK_DETAILS}/${orderId}?v=2`),
    [history],
  );

  const redirectTo = useCallback(
    (event: MouseEvent, orderId: number | string) => {
      // Keep the shortcuts to open link in a new tab/window and open the context menu via key press + click.
      if (event.ctrlKey || event.shiftKey || event.metaKey) return;

      // If no keys were pressed, suppress the click and handle the redirection manually.
      event.preventDefault();
      track(amplitudeTag());
      return type === OrderType.Checkout
        ? goToTransactionDetails(orderId)
        : goToPaymentLinkDetails(orderId);
    },
    [amplitudeTag, goToPaymentLinkDetails, goToTransactionDetails, type],
  );

  const buildStoreURL = (path: string) => `${buildAbsoluteStoreURL(path)}?v=2`;

  return (
    <Link
      data-testid={`dashboardField-${orderId}`}
      appearance="primary"
      as="a"
      onClick={(event: MouseEvent) => redirectTo(event, orderId)}
      textDecoration="none"
      href={buildStoreURL(detailsPath)}
    >
      {orderNumber ? t(type, { orderNumber: orderNumber }) : '---'}
    </Link>
  );
}

function Skeleton(): JSX.Element {
  return <Text.Skeleton width="100%" height="20px" />;
}

DashboardLinkField.Skeleton = Skeleton;

export default DashboardLinkField;
