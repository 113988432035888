import React from 'react';
import { useHistory } from 'react-router';
import { Country } from 'App/i18n/enums';
import { ROUTES } from 'App/Routes/constants';
import { FeatureDiscoveryPopover } from 'commons/components';
import { useFeatureFlag } from 'commons/hooks';
import { FeatureFlag } from 'commons/services';
import { FeatureName } from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';
import { useTranslationWithPrefix } from 'commons/utils';

function WalletFeatureDiscoveryPopover({
  children,
  onlyForCountries,
}: {
  children: React.ReactNode;
  onlyForCountries?: Country[];
}): JSX.Element {
  const { t } = useTranslationWithPrefix('walletDiscovery');
  const featureFlag = useFeatureFlag(FeatureFlag.FS_PAGONUBE_QR_MODO_ENABLED);

  const { push } = useHistory();

  const redirectToPaymentMethods = () => {
    push(ROUTES.PAYMENT_METHODS);
  };

  if (!featureFlag.enabled || featureFlag.loading) return <>{children}</>;

  return (
    <FeatureDiscoveryPopover
      title={t('title')}
      description={t('description')}
      featureName={FeatureName.WalletPaymentMethod}
      linkText={t('linkText')}
      onLinkClick={redirectToPaymentMethods}
      onlyForCountries={onlyForCountries}
      amplitudeTag="pagonube_popover_config_modo"
      maxWidth="350px"
    >
      <>{children}</>
    </FeatureDiscoveryPopover>
  );
}

export default WalletFeatureDiscoveryPopover;
