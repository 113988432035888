/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ROUTES } from 'App/Routes/constants';
import { notificationService } from 'commons/services';
import { bankingService } from 'domains/Brazil/commons/services/bankingService';
import identityService from 'domains/Brazil/commons/services/identity/identityService';
import BasicLayout from './components/BasicLayout';
import { SelfieInstructionsList } from './components/InstructionsList/SelfieInstructionsList';
import { Step } from './components/StepNavigator/components/Step';
import {
  BeforeMountStepsType,
  StepNavigator,
  StepNavigatorContext,
} from './components/StepNavigator/StepNavigator';
import IntroductionStepPage from './pages/IntroductionStepPage/IntroductionStepPage';
import IntroductionStepPageSkeleton from './pages/IntroductionStepPage/IntroductionStepPage.skeleton';
import SelectableDocumentUploader from './pages/SelectDocumentStepPage/SelectDocumentsStepPage';
import SingleDocumentUploader from './pages/SingleDocumentUploader/SingleDocumentUploader';
import { InterfaceSolicitation, RecipientInfo } from './utils/interfaces';
import { trackEvent } from './utils/tracking';
import { DocumentType } from './utils/types/DocumentType.enum';

interface LocationState {
  notificationIdOrigin?: string;
}

async function deleteNotification(notificationIdOrigin: string) {
  try {
    await notificationService.deleteNotification(notificationIdOrigin);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error deleting notification', error);
  }
}

type UseIdentityValidationProps = {
  retryFetchCount: number;
};
function useIdentityValidation({
  retryFetchCount,
}: UseIdentityValidationProps) {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { notificationIdOrigin } = location.state || {};

  const [hasError, setHasError] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [recipientInfo, setRecipientInfo] = useState<RecipientInfo | null>(
    null,
  );
  const [documentSolicitations, setDocumentSolicitations] = useState<
    Map<DocumentType, InterfaceSolicitation> | undefined
  >();

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      setHasError(false);

      try {
        const [fetchedMissingInfo, fetchedDocumentSolicitations] =
          await Promise.all([
            identityService.getMissingInfo(),
            bankingService.getDocumentSolicitations(),
          ]);

        setRecipientInfo({
          document: fetchedMissingInfo.document,
          recipientType: fetchedMissingInfo.recipientType,
          identityId: fetchedMissingInfo.identityId,
        });

        setDocumentSolicitations(fetchedDocumentSolicitations);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Erro ao buscar dados iniciais', error);
        setHasError(true);
        trackEvent('load_error');
      } finally {
        setIsFetching(false);
      }
    };

    fetchData();
  }, [retryFetchCount]);

  const goToDashboard = useCallback(() => {
    history.push(ROUTES.DASHBOARD);
  }, [history]);

  return {
    hasError,
    isFetching,
    recipientInfo,
    documentSolicitations,
    goToDashboard,
    notificationIdOrigin,
  };
}

type DocumentStepData = {
  documentTypes?: DocumentType[];
  recipientInfo?: RecipientInfo;
  solicitations?: InterfaceSolicitation[];
};

interface IdentityValidationStepProps {
  documentTypes?: DocumentType[];
  solicitations?: InterfaceSolicitation[];
  children?: React.ReactElement;
  isDocumentResend: boolean;
}

const filterStepsToRender: BeforeMountStepsType<
  IdentityValidationData,
  DocumentStepData
> = ({ steps, data }) => {
  const solicitations = data.documentSolicitations;
  const stepsToRender: typeof steps = [];

  steps.forEach((step) => {
    const stepProps = step.props;

    if (stepProps.documentTypes) {
      const hasSolicitations = stepProps.documentTypes.some(
        (type) => solicitations?.has(type),
      );

      if (hasSolicitations) {
        const solicitations: InterfaceSolicitation[] = stepProps.documentTypes
          .map((type) => data.documentSolicitations?.get(type))
          .filter(Boolean) as InterfaceSolicitation[];

        const stepWithSolicitations = React.cloneElement(step, {
          solicitations,
        });

        stepsToRender.push(stepWithSolicitations);
      }
    }

    if (!stepProps.documentTypes) {
      stepsToRender.push(step);
    }
  });

  return stepsToRender;
};

function IntroductionStep() {
  const stepContext = useContext(StepNavigatorContext);
  if (!stepContext) return null;
  const { data, previousStep, nextStep } = stepContext;

  return (
    <Step>
      <IntroductionStepPage
        recipientInfo={data?.recipientInfo}
        previousStep={previousStep}
        nextStep={nextStep}
      />
    </Step>
  );
}

function DocumentStep(props: IdentityValidationStepProps) {
  const navigatorContext = useContext(StepNavigatorContext);
  if (!navigatorContext) return null;

  const { solicitations, documentTypes, isDocumentResend } = props;
  const { data, previousStep, nextStep } = navigatorContext;

  if (!data?.recipientInfo) return null;

  if (documentTypes && documentTypes.length > 1) {
    return (
      <Step>
        <SelectableDocumentUploader
          solicitations={solicitations}
          recipientInfo={data.recipientInfo}
          documentTypes={documentTypes}
          previousStep={previousStep}
          nextStep={nextStep}
          isDocumentResend={isDocumentResend}
        >
          {props.children}
        </SelectableDocumentUploader>
      </Step>
    );
  }

  return (
    <Step>
      <SingleDocumentUploader
        recipientInfo={data.recipientInfo}
        previousStep={previousStep}
        nextStep={nextStep}
        isDocumentResend={isDocumentResend}
      >
        <SelfieInstructionsList />
      </SingleDocumentUploader>
    </Step>
  );
}

type IdentityValidationData = {
  recipientInfo?: RecipientInfo | null;
  documentSolicitations?: Map<DocumentType, InterfaceSolicitation>;
};

type IdentityValidationProps = {
  isDocumentResend?: boolean;
};

function IdentityValidation(
  props: IdentityValidationProps,
): JSX.Element | null {
  const isDocumentResend = props.isDocumentResend || false;

  const [retryFetchCount, setRetryFetchCount] = useState(0);

  const {
    hasError,
    isFetching,
    recipientInfo,
    documentSolicitations,
    notificationIdOrigin,
    goToDashboard,
  } = useIdentityValidation({ retryFetchCount });

  const onFirstStepCancel = useCallback(() => {
    goToDashboard();
  }, [goToDashboard]);

  const onLastStepComplete = useCallback(() => {
    if (notificationIdOrigin) deleteNotification(notificationIdOrigin);

    goToDashboard();
  }, [goToDashboard, notificationIdOrigin]);

  const retryFetch = useCallback(() => {
    setRetryFetchCount((prev) => prev + 1);
    trackEvent('introduction_retry');
  }, []);

  if (isFetching) {
    return <IntroductionStepPageSkeleton />;
  }

  return (
    <BasicLayout error={hasError} refreshHandler={retryFetch}>
      <StepNavigator<IdentityValidationData, DocumentStepData>
        onFirstStepCancel={onFirstStepCancel}
        onLastStepComplete={onLastStepComplete}
        beforeMountSteps={filterStepsToRender}
        data={{ recipientInfo, documentSolicitations }}
      >
        {!isDocumentResend ? <IntroductionStep /> : null}
        <DocumentStep
          documentTypes={[DocumentType.CNH, DocumentType.RG]}
          isDocumentResend={isDocumentResend}
        />
        <DocumentStep
          documentTypes={[DocumentType.SELFIE]}
          isDocumentResend={isDocumentResend}
        />
      </StepNavigator>
    </BasicLayout>
  );
}

export default IdentityValidation;
