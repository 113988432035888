import { getStatusTagCode } from 'commons/services/dashboardServiceV2/status-tag.service';
import { PaymentLinkDetailsType } from 'commons/types/PaymentLinkDetailsType';
import { TypeTagV2 } from 'domains/Brazil/commons/components/StatusTagV2';
import {
  ChargesDashboardStatus,
  FailureReason,
} from '../dashboardServiceV2/dashboard.service';
import { GetPaymentLinkResponse } from './paymentLinkService';

export default class PaymentLinkDetailsFactoryV2 {
  private static formatRejectedMessage = (
    status: TypeTagV2,
    rejectedMessage?: string,
  ) => {
    if (status == TypeTagV2.RejectedBySecurity)
      return 'card_rejected_by_security';
    else if (status == TypeTagV2.Failed) return rejectedMessage;
    else return undefined;
  };

  public static createFromApiResponseData(
    data: GetPaymentLinkResponse,
  ): PaymentLinkDetailsType {
    const status = getStatusTagCode(
      data?.status as ChargesDashboardStatus,
      data?.failureReason as FailureReason,
    );

    return {
      id: data?.id,
      customer: {
        ...data?.customer,
        phone: `${data?.customer?.phone?.countryCode} ${data?.customer?.phone?.areaCode} ${data?.customer?.phone?.number}`,
      },
      description: data?.descriptions,
      linkNumber: data?.linkNumber,
      payment: {
        ...data?.payment,
        cardNumber: Number(data?.payment?.cardNumber),
        rejectedMessage: this.formatRejectedMessage(
          status,
          data?.rejectedMessage,
        ),
      },
      paymentUrl: data?.paymentUrl,
      shippingAddress: data?.shippingAddress,
      status,
      createdAt: data?.createdAt,
      referenceUUID: data?.referenceUuid,
      failureReason: data?.failureReason,
    };
  }
}
