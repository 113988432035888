import React, { useCallback } from 'react';
import { Box, Icon, Link, Tag, Text } from '@nimbus-ds/components';
import { CalendarIcon } from '@nimbus-ds/icons';
import { useIsMobile, useStoreURL, useUnobstructiveClick } from 'commons/hooks';
import {
  ChargesDashboardStatus,
  FailureReason,
} from 'commons/services/dashboardServiceV2/dashboard.service';
import { getStatusTagCode } from 'commons/services/dashboardServiceV2/status-tag.service';
import { TransactionDetailsType } from 'commons/types';
import { redirectToOrder, useTranslationWithPrefix } from 'commons/utils';
import StatusTag from 'domains/Brazil/commons/components/StatusTagV2';

const ptBrDateString = (date: string) =>
  new Date(date).toLocaleDateString('pt-br', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });

function NuvemshopOrderLink({
  orderId,
  orderNumber,
}: Readonly<{
  orderId: number;
  orderNumber: number;
}>): JSX.Element {
  const { t } = useTranslationWithPrefix('transactions.details.header');
  const { buildAbsoluteStoreURL } = useStoreURL();

  const isAbandonnedCart = (orderNumber ?? -1) < 0;
  const absoluteOrderUrl = buildAbsoluteStoreURL(
    `/admin/v2/${isAbandonnedCart ? 'abandoned-carts' : 'orders'}/${orderId}`,
  );

  const redirectToNsOrderDetail = useCallback(() => {
    redirectToOrder(orderId, isAbandonnedCart);
  }, [isAbandonnedCart, orderId]);
  const onClick = useUnobstructiveClick(redirectToNsOrderDetail);

  return (
    <Link
      appearance="primary"
      as="a"
      data-testid="moreInfoLink"
      onClick={onClick}
      href={absoluteOrderUrl}
    >
      {t(isAbandonnedCart ? 'abandonedCartLinkText' : 'orderLinkText')}
    </Link>
  );
}

export type HeaderBoxProps = {
  readonly details: TransactionDetailsType;
  readonly extraContent?: React.ReactNode;
};

export default function HeaderBox({
  details,
  extraContent,
}: HeaderBoxProps): JSX.Element {
  const statusTag = getStatusTagCode(
    details.status as ChargesDashboardStatus,
    details.failureReason as FailureReason,
  );

  return (
    <Box display="flex" flexDirection="column" gap="4">
      <StatusTag type={statusTag} />
      <Box display="flex" flexDirection="column" gap="3-5">
        <Box display="flex" flexDirection="row" gap="3-5">
          <Box display="flex" flexDirection="row" gap="2">
            <Icon source={<CalendarIcon />} />
            <Text fontSize="caption" textAlign="left" color="neutral-textLow">
              {ptBrDateString(details.createdAt)}
            </Text>
          </Box>
          <NuvemshopOrderLink
            orderId={details.order.id}
            orderNumber={details.transactions[0].orderNumber}
          />
        </Box>
        {extraContent}
      </Box>
    </Box>
  );
}

function Skeleton(): JSX.Element {
  const isMobile = useIsMobile();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="4"
      data-testid="headerBoxSkeleton"
    >
      <Tag.Skeleton width={isMobile ? '58px' : '104px'} height="18px" />
      <Text.Skeleton width="144px" />
    </Box>
  );
}

HeaderBox.Skeleton = Skeleton;
