import React from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { TextByCountry } from 'commons/components/TextByCountry';
import {
  formatCuit,
  getCuitOrCuilLabel,
  useTranslationWithPrefix,
} from 'commons/utils';

interface InterfaceFiscalDocument {
  fiscalNumber: string;
}

const FiscalDocument = ({
  fiscalNumber,
}: InterfaceFiscalDocument): JSX.Element => {
  const { t } = useTranslationWithPrefix('configuration.personalData');

  return (
    <Box
      gap="4"
      display="flex"
      flexDirection="column"
      borderWidthX="none"
      borderTopWidth="none"
      borderBottomWidth="1"
      borderColor="neutral-surface"
      borderStyle="solid"
      paddingBottom="4"
    >
      <Box gap="2" display="flex" flexDirection="column">
        <Text color="neutral-textHigh">
          <TextByCountry
            AR={`${getCuitOrCuilLabel(fiscalNumber)} ${t('registered')}`}
            BR={`${getCuitOrCuilLabel(fiscalNumber)} ${t('registered')}`}
            MX={`${t('rfc')} ${t('registered')}`}
          />
        </Text>
        <Text color="neutral-textHigh">
          <TextByCountry
            AR={formatCuit(fiscalNumber)}
            BR={formatCuit(fiscalNumber)}
            MX={fiscalNumber}
          />
        </Text>
      </Box>
      <Text fontSize="caption" color="neutral-textDisabled">
        {t('contactSupport')}
      </Text>
    </Box>
  );
};

export default FiscalDocument;
