import { useEffect, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  configurationService,
  GetInstallmentsV2Response,
} from 'commons/services';
import {
  InstallmentsFeeResponsible,
  InstallmentV2Type,
  MoneyObjectInterface,
} from 'commons/types';

export type SelectedInstallments = {
  withoutInterest: InstallmentV2Type[];
  planAhora?: InstallmentV2Type[];
};

type InstallmentsState = SelectedInstallments & {
  changingResponsible: boolean;
  responsible: InstallmentsFeeResponsible;
};

export type ResponsibleStatus =
  | 'customer_active'
  | 'merchant_active'
  | 'changing_to_customer'
  | 'changing_to_merchant';

export function useInstallmentsV2(opts: {
  onResponsibleChange: {
    success: (responsibleChangedTo: InstallmentsFeeResponsible) => void;
    error: () => void;
  };
}) {
  const [installments, setInstallments] = useState<SelectedInstallments>({
    withoutInterest: [],
    planAhora: [],
  });
  const [installmentsChanged, setInstallmentsChanged] = useState(false);
  const [
    installmentMinOrderAmountChanged,
    setInstallmentMinOrderAmountChanged,
  ] = useState(false);
  const changed = installmentsChanged || installmentMinOrderAmountChanged;

  const queryKey = ['installmentsV2'];
  const queryClient = useQueryClient();
  const queryInstallments = useQuery<
    GetInstallmentsV2Response,
    unknown,
    InstallmentsState
  >(queryKey, () => configurationService.getInstallmentsV2(), {
    onSuccess(data) {
      setInstallments({
        withoutInterest: data.withoutInterest,
        planAhora: data.planAhora,
      });
    },
  });

  const currentResponsible = queryInstallments.data?.responsible;
  const responsibleToChange =
    currentResponsible === InstallmentsFeeResponsible.MERCHANT
      ? InstallmentsFeeResponsible.CUSTOMER
      : InstallmentsFeeResponsible.MERCHANT;
  const changingResponsible = queryInstallments.data?.changingResponsible;
  const responsibleStatus: ResponsibleStatus = changingResponsible
    ? currentResponsible === InstallmentsFeeResponsible.CUSTOMER
      ? 'changing_to_merchant'
      : 'changing_to_customer'
    : currentResponsible === InstallmentsFeeResponsible.CUSTOMER
      ? 'customer_active'
      : 'merchant_active';

  useEffect(() => {
    if (changingResponsible) {
      track('pago-nube-error-cambio-cuotas');
    }
  }, [changingResponsible]);

  const setChanged = (changed: boolean) => {
    setInstallmentsChanged(changed);
    setInstallmentMinOrderAmountChanged(changed);
  };

  const updateInstallments = (installments: SelectedInstallments) => {
    setInstallments({
      withoutInterest: installments.withoutInterest,
      planAhora: installments.planAhora,
    });
    setInstallmentsChanged(true);
  };

  const updateInstallmentMinOrderAmount = (
    id: number,
    minOrderAmount: MoneyObjectInterface,
  ) => {
    setInstallments((prev) => {
      const updatedPlanAhora = prev.planAhora?.map((installment) =>
        installment.id === id
          ? { ...installment, minOrderAmount }
          : installment,
      );

      const updatedWithoutInterest = prev.withoutInterest.map((installment) =>
        installment.id === id
          ? { ...installment, minOrderAmount }
          : installment,
      );

      return {
        ...prev,
        planAhora: updatedPlanAhora,
        withoutInterest: updatedWithoutInterest,
      };
    });

    setInstallmentMinOrderAmountChanged(true);
  };

  const mutateInstallments = useMutation(queryKey, {
    mutationFn: () => {
      // TODO: remove this function when commons stops sending by default the old minInstallmentValue
      const removeMinOrderAmountFrom = (installments?: InstallmentV2Type[]) =>
        installments?.map((installment) => ({
          id: installment.id,
          quantity: installment.quantity,
          enabled: installment.enabled,
          interest: installment.interest,
          type: installment.type,
        }));

      // if minimum amount changed, do not apply the filter
      const installmentsToUpdate = {
        planAhora: installmentMinOrderAmountChanged
          ? installments.planAhora
          : removeMinOrderAmountFrom(installments.planAhora),
        withoutInterest: installmentMinOrderAmountChanged
          ? installments.withoutInterest
          : removeMinOrderAmountFrom(installments.withoutInterest),
      };
      return configurationService.patchInstallmentsV2(installmentsToUpdate);
    },
    onSuccess: () => {
      if (installmentMinOrderAmountChanged) {
        track('pago-nube-guardar-monto-ok');
      }
      queryInstallments.refetch();
    },
    onError: () => {
      if (installmentMinOrderAmountChanged) {
        track('pago-nube-guardar-monto-error');
      }
    },
  });

  const mutateResponsible = useMutation(
    ['responsible'],
    () => {
      return configurationService.patchInstallmentsResponsible(
        responsibleToChange,
      );
    },
    {
      onSuccess() {
        queryClient.setQueryData<InstallmentsState>(queryKey, (old) => ({
          ...old!,
          changingResponsible: true,
        }));
        opts.onResponsibleChange.success(responsibleToChange);
      },
      onError() {
        opts.onResponsibleChange.error();
      },
    },
  );

  return {
    installments: {
      withoutInterest: installments.withoutInterest,
      planAhora: installments.planAhora,
    },
    responsible: {
      status: responsibleStatus,
    },
    changed,
    isLoadingInstallments: queryInstallments.isLoading,
    isErrorInstallments: queryInstallments.isError,
    setChanged,
    updateInstallments,
    changeResponsible: mutateResponsible,
    submitInstallments: mutateInstallments,
    updateInstallmentMinOrderAmount,
  };
}
