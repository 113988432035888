import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box, Button, Link, Popover, Text } from '@nimbus-ds/components';
import { DownloadIcon, EllipsisIcon } from '@nimbus-ds/icons';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';
import AppLayout from 'App/components/AppLayout';
import { Country } from 'App/i18n/enums';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { RestrictVisibility } from 'commons/components';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import WalletFeatureDiscoveryPopover from './shared/WalletFeatureDiscoveryPopover';
import WithdrawsCard from './WithdrawsCard/WithdrawsCard';
import BlockedBanner from './BlockedBanner';
import ChargebackAlerts from './ChargebackAlerts';
import { ConfigButtonHeader, ConfigLink } from './ConfigLink';
import DisabledBanner from './DisabledBanner';
import FreemiumWarningModal from './FreemiumWarningModal';
import HomeCards from './HomeCards';
import HomeNotifications from './HomeNotifications';
import HomeSkeleton from './HomeSkeleton';
import { HomeTableDesktop, HomeTableMobile } from './HomeTable';
import MinOrderAmountFeatureModal from './MinOrderAmountFeatureModal';
import PendingDocBanner from './PendingDocBanner';
import ProofOfLifeErrorHomeAlert from './ProofOfLifeErrorHomeAlert';
import ProofOfLifePendingBanner from './ProofOfLifePendingBanner';

function Home(): JSX.Element {
  const { t } = useTranslationWithPrefix('home');
  const isMobile = useIsMobile();

  const history = useHistory();

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  function openReportSidebar() {
    track('nuvempago_dashboard_exportar_listado_click');
    history.push(ROUTES.TRANSACTIONS_REPORT);
  }

  const homeMenuContent = (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <ConfigLink />
    </Box>
  );

  const desktopHeaderItems = (
    <Box display="flex" gap="4">
      <ConfigButtonHeader />
      <RestrictVisibility view="report">
        <Button onClick={openReportSidebar} appearance="primary">
          <DownloadIcon />
          {t('report')}
        </Button>
      </RestrictVisibility>
    </Box>
  );

  const mobileNavBarItems = (
    <Box display="flex" gap="4">
      <RestrictVisibility view="report">
        <Link onClick={openReportSidebar} textDecoration="none">
          <DownloadIcon />
          <Text color="primary-textHigh" fontSize="base">
            {t('report')}
          </Text>
        </Link>
        <WalletFeatureDiscoveryPopover onlyForCountries={[Country.ARGENTINA]}>
          <Popover
            content={homeMenuContent}
            position="bottom-end"
            arrow={false}
          >
            <Link textDecoration="none">
              <EllipsisIcon />
            </Link>
          </Popover>
        </WalletFeatureDiscoveryPopover>
      </RestrictVisibility>
    </Box>
  );

  return (
    <AppLayout
      hideBackButton
      title={t('title')}
      headerItems={isMobile ? null : desktopHeaderItems}
      navBarItems={mobileNavBarItems}
    >
      <Box display="flex" flexDirection="column" gap="6" alignItems="stretch">
        <RestrictVisibility view="minOrderAmountFeatureModal">
          <MinOrderAmountFeatureModal />
        </RestrictVisibility>
        <ProofOfLifeErrorHomeAlert />
        <HomeNotifications />
        <RestrictVisibility view="banners">
          <>
            <DisabledBanner />
            <BlockedBanner />
            <PendingDocBanner />
            <ProofOfLifePendingBanner />
          </>
        </RestrictVisibility>
        <ChargebackAlerts />
        <HomeCards />
        <RestrictVisibility view="withdraws">
          <WithdrawsCard />
        </RestrictVisibility>
        {isMobile ? <HomeTableMobile /> : <HomeTableDesktop />}
      </Box>
      <FreemiumWarningModal />
    </AppLayout>
  );
}

Home.Skeleton = HomeSkeleton;
export default Home;
