import React, { useEffect, useState } from 'react';
import { Box, Card } from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile, useToast } from 'commons/hooks';
import { notificationService } from 'commons/services';
import { useTranslationWithPrefix } from 'commons/utils';
import { bankingService } from 'domains/Brazil/commons/services/bankingService';
import { AlertFileSizeError } from '../../components/AlertFileSizeError';
import BasicLayout from '../../components/BasicLayout';
import FooterButtons from '../../components/FooterButtons';
import ThumbnailFileUploader from '../../components/ThumbnailFileUploader';
import {
  BankingIdentityValidationPageProps,
  Document,
  RecipientInfo,
} from '../../utils/interfaces';
import { trackUpload } from '../../utils/tracking';
import { DocumentSide } from '../../utils/types/DocumentSide.enum';
import { DocumentType } from '../../utils/types/DocumentType.enum';

export type SingleDocumentUploadProps = BankingIdentityValidationPageProps & {
  notificationIdToDelete?: string;
  recipientInfo: RecipientInfo;
  children: JSX.Element;
};

function SingleDocumentUploader({
  nextStep,
  previousStep,
  notificationIdToDelete,
  recipientInfo,
  children,
  isDocumentResend,
}: SingleDocumentUploadProps): JSX.Element | null {
  const isMobile = useIsMobile();
  const { showToastSuccess, showToastError } = useToast();
  const { t: navT } = useTranslationWithPrefix('navigation');
  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.selfieUploadPage',
  );

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasFileSizeError, setFileSizeError] = useState(false);
  const [MAX_10MB] = useState(10485760);
  const [selfieFile, setSelfieFile] = useState<File | null>(null);

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });

    if (!isLoading) return;
    if (error) return;

    setLoading(true);
    setError(false);
  }, [isLoading, error, navT]);

  const refreshOnError = () => {
    setError(false);
  };

  function onSelectFileHandler(file: File) {
    setSelfieFile(file);
    showToastSuccess(t('toast.success'));
    setFileSizeError(false);
    setError(false);
  }

  function onDeleteFileHandler() {
    setSelfieFile(null);
    setFileSizeError(false);
  }

  function onErrorHandler() {
    setFileSizeError(true);
    showToastError(t('toast.error'));
  }

  async function uploadFile() {
    setLoading(true);

    if (!selfieFile) return;

    const extension = bankingService.extractFileExtension(selfieFile);

    if (!extension) {
      setError(true);
      return;
    }

    const document: Document = {
      documentType: DocumentType.SELFIE,
      documentSide: DocumentSide.FRONT,
    };

    try {
      await bankingService.addAccountDocument({ document, file: selfieFile });

      trackUpload(
        recipientInfo.recipientType,
        DocumentType.SELFIE,
        isDocumentResend,
      );

      if (notificationIdToDelete) {
        await notificationService.deleteNotification(notificationIdToDelete);
      }

      nextStep();
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <BasicLayout
      title={t('title')}
      step={t('step')}
      error={error}
      refreshHandler={refreshOnError}
    >
      <Box
        paddingLeft="none"
        height={isMobile ? '100%' : '400px'}
        maxWidth="1200px"
        data-testid="SingleDocumentUploader"
      >
        <Box
          paddingLeft="none"
          id="instructions"
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
        >
          <Card>
            <Card.Body>
              <Box
                display="flex"
                gap="6"
                paddingTop="2"
                flexDirection={isMobile ? 'column' : 'row-reverse'}
              >
                <Box
                  display="flex"
                  flex="1"
                  flexDirection="column"
                  gap="4"
                  alignItems="flex-start"
                >
                  {children}
                </Box>

                {hasFileSizeError && <AlertFileSizeError />}

                <Box flex="1">
                  <ThumbnailFileUploader
                    data-testid="ThumbnailFileUploader"
                    accept="image/jpeg,image/png"
                    ariaLabel="selfie"
                    aspectRatio="16/9"
                    fileName="selfie"
                    helperText="Selfie"
                    maxFileSize={MAX_10MB}
                    onDelete={onDeleteFileHandler}
                    onError={onErrorHandler}
                    onSelectFile={onSelectFileHandler}
                  />
                </Box>
              </Box>
            </Card.Body>
          </Card>
        </Box>
        <Box display="flex" gap="4" flexDirection="column">
          <FooterButtons
            primaryButtonHandler={uploadFile}
            secondaryButtonHandler={previousStep}
            isDisabled={hasFileSizeError || !selfieFile}
            isSubmitting={isLoading}
          />
        </Box>
      </Box>
    </BasicLayout>
  );
}

export default SingleDocumentUploader;
