import React from 'react';
import { Text } from '@nimbus-ds/components';

interface FormattedMultilineTextProps
  extends React.ComponentProps<typeof Text> {
  children: string;
}

export function FormattedMultilineText({
  children,
  ...rest
}: FormattedMultilineTextProps) {
  const parseLine = (line: string, index: number) => {
    const parts = line.split(/(<b>.*?<\/b>)/g).filter(Boolean);

    return (
      <Text key={index} {...rest}>
        {parts.map((part, i) => {
          const boldMatch = part.match(/^<b>(.*?)<\/b>$/);
          if (boldMatch) {
            return (
              <Text as="span" fontWeight="medium" key={i}>
                {boldMatch[1]}
              </Text>
            );
          }
          return <span key={i}>{part}</span>;
        })}
      </Text>
    );
  };

  return (
    <>
      {children
        .split('\n')
        .filter((s) => !!s)
        .map((line, i) => parseLine(line, i))}
    </>
  );
}
