import React from 'react';
import {
  Alert,
  Box,
  Card,
  Radio,
  Tag,
  Text,
  Title,
} from '@nimbus-ds/components';
import { ErrorMessage } from 'commons/components';
import { InstallmentV2Type, MoneyObjectInterface } from 'commons/types';
import { formatPercentage, useTranslationWithPrefix } from 'commons/utils';
import InstallmentsCheckbox from '../shared/InstallmentsCheckbox/InstallmentsCheckbox';
import { InstallmentSectionInactive } from '../shared/InstallmentSectionInactive';
import { ResponsibleStatus, SelectedInstallments } from '../useInstallmentsV2';
import MinOrderAmountInput from './MinOrderAmountInput';

interface RenderInstallmentsInterface {
  name: string;
  installmentList: InstallmentV2Type[];
  forceDisable?: boolean;
}

export interface WithoutInterestInterface {
  installments?: SelectedInstallments;
  setInstallments: (installments: SelectedInstallments) => void;
  configDisabled: boolean;
  hasError: boolean;
  openSwitchModal: () => void;
  status: ResponsibleStatus;
  updateInstallmentMinOrderAmount: (
    id: number,
    minOrderAmount: MoneyObjectInterface,
  ) => void;
}

export function WithoutInterest({
  installments,
  setInstallments,
  configDisabled,
  hasError,
  openSwitchModal,
  status,
  updateInstallmentMinOrderAmount,
}: WithoutInterestInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('installments.withoutInterest');

  if (hasError)
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Title as="h4">{t('title')}</Title>
        <ErrorMessage />
      </Box>
    );

  const installmentLabel = (installment: InstallmentV2Type) => {
    if (installment.quantity === 1) return t('installments.one');

    return installment.interest
      ? t('installments.many', {
          quantity: installment.quantity,
          rate: formatPercentage(installment.interest),
        })
      : t('installments.many_unknown_interest', {
          quantity: installment.quantity,
        });
  };

  const shouldRenderCheapTagByType = (installment: InstallmentV2Type) => {
    const isSpecialInstallmentWithQuantity = (quantity: number) =>
      installment.type === 'special' && installment.quantity === quantity;

    const isPlanAhora = installment.type === 'plan_ahora';

    return (
      isPlanAhora ||
      isSpecialInstallmentWithQuantity(3) ||
      isSpecialInstallmentWithQuantity(6)
    );
  };

  const cheapInstallmentTag = (installment: InstallmentV2Type) =>
    shouldRenderCheapTagByType(installment) && (
      <Tag appearance="neutral">
        <Text fontSize="caption" color="neutral-textLow">
          {t('planAhora')}
        </Text>
      </Tag>
    );

  const renderInstallments = ({
    name,
    installmentList,
    forceDisable,
  }: RenderInstallmentsInterface) => {
    return (
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        gap="2-5"
        width="100%"
      >
        {installmentList.map((installment, idx) => (
          <Card key={`installment-${name}-${idx}`}>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap="2"
            >
              <InstallmentsCheckbox
                configDisabled={configDisabled}
                installment={installment}
                installmentLabel={installmentLabel}
                installmentList={installmentList}
                name={name}
                setInstallments={setInstallments}
                forceDisable={forceDisable}
                installments={installments}
              />
              {cheapInstallmentTag(installment)}
            </Box>
            {installment.enabled && (
              <MinOrderAmountInput
                value={installment.minOrderAmount}
                onChange={updateInstallmentMinOrderAmount}
                installmentId={installment.id}
                installmentQuantity={installment.quantity}
                disabled={forceDisable}
              />
            )}
          </Card>
        ))}
      </Box>
    );
  };

  const InstallmentsSection = (forceDisable?: boolean) => {
    return (
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        gap="4"
      >
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          gap="2"
          width="100%"
        >
          {renderInstallments({
            name: 'withoutInterest',
            installmentList: installments?.withoutInterest || [],
            forceDisable,
          })}
        </Box>
      </Box>
    );
  };

  switch (status) {
    case 'changing_to_customer':
      return (
        <InstallmentSectionInactive
          changingResponsible
          translationPrefix="installments.withoutInterest"
        >
          {InstallmentsSection(true)}
        </InstallmentSectionInactive>
      );

    case 'changing_to_merchant':
      return (
        <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="2-5">
              <Title as="h4">{t('title')}</Title>
              <Tag appearance="warning">{t('inProgress')}</Tag>
            </Box>
            <Radio name="without-interest" checked={false} disabled readOnly />
          </Box>

          <Text color="neutral-textHigh" fontSize="base">
            {t('activeSubtitle')}
          </Text>

          <Alert show appearance="neutral">
            {t('changeInProgress')}
          </Alert>
          {InstallmentsSection(true)}
        </Box>
      );

    case 'merchant_active':
      return (
        <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="2-5">
              <Title as="h4">{t('title')}</Title>
              <Tag appearance="success">{t('active')}</Tag>
            </Box>
            <Radio name="without-interest" checked readOnly />
          </Box>
          <Text color="neutral-textHigh" fontSize="base">
            {t('activeSubtitle')}
          </Text>
          {InstallmentsSection()}
        </Box>
      );

    case 'customer_active':
    default:
      return (
        <InstallmentSectionInactive
          translationPrefix="installments.withoutInterest"
          openSwitchModal={openSwitchModal}
        >
          {InstallmentsSection(true)}
        </InstallmentSectionInactive>
      );
  }
}
