import React from 'react';
import { Tag } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import RenderOnError from 'domains/Brazil/commons/components/RenderOnError';

export const defaultRefreshOnError = () => {};

type ContentOrErrorProps = {
  title?: string;
  subtitle?: string;
  step?: string;
  error: boolean;
  children: JSX.Element;
  refreshHandler?: () => void;
};

type LayoutProps = {
  title?: string;
  subtitle?: string;
  step?: string;
  children: JSX.Element;
};

const Step = ({ text }: { text: string }) => (
  <Tag key={text} appearance="neutral">
    {text}
  </Tag>
);

const PageLayout = ({ title, subtitle, step, children }: LayoutProps) => {
  const isMobile = useIsMobile();
  return (
    <AppLayout
      title={title ?? ''}
      subtitle={subtitle}
      subtitleItems={[step && <Step key="1" text={step} />]}
      backLinkPath={ROUTES.DASHBOARD}
      backLinkText={isMobile ? '' : undefined}
    >
      {children}
    </AppLayout>
  );
};

function BasicLayout({
  title = '',
  subtitle,
  step,
  error,
  refreshHandler,
  children,
}: ContentOrErrorProps) {
  const { t } = useTranslationWithPrefix('banking.identityValidation');

  return (
    <PageLayout title={title} subtitle={subtitle} step={step}>
      <RenderOnError
        title={t('loadingError.title')}
        message=""
        label={t('loadingError.tryAgain')}
        error={!!error}
        refreshHandler={refreshHandler ?? defaultRefreshOnError}
      >
        {children}
      </RenderOnError>
    </PageLayout>
  );
}

export default BasicLayout;
