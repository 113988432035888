import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import RequirePermission from 'App/components/RequirePermission/RequirePermission';
import RequireRole from 'App/components/RequireRole';
import RequireVerification from 'App/components/RequireVerification/RequireVerification';
import InactiveStoreRoutes from 'commons/components/InactiveStoreRoutes/InactiveStoreRoutes';
import NotificationProvider from 'commons/components/NotificationProvider/NotificationProvider';
import { Roles } from 'commons/components/Roles/Roles.enum';
import { PermissionFeature } from 'commons/hooks';
import useStoreInfo from 'commons/hooks/useStoreInfo';
import {
  BankAccountRegistration,
  Configuration,
  HomeMexico,
  Installments,
  Landing,
  Refund,
  TransactionDetail,
  TransactionsReport,
  Transfer,
  Withdraws,
} from 'domains';
import { clabeValidator } from 'domains/BankAccountRegistration/BankAccountValidator/Mexico/CLABEValidator';
import { ROUTES } from '../constants';

function MexicoRoutes(): JSX.Element {
  const { storeInfo } = useStoreInfo();

  const activeStoresRoutes = (
    <Switch>
      <Route exact path={ROUTES.HOME}>
        <Suspense fallback={null}>
          <NotificationProvider>
            <HomeMexico />
          </NotificationProvider>
        </Suspense>
      </Route>

      <Route exact path={`${ROUTES.TRANSACTION_DETAILS}/:orderId`}>
        <Suspense fallback={null}>
          <TransactionDetail />
        </Suspense>
      </Route>

      <Route
        exact
        path={ROUTES.TRANSFER}
        render={() => (
          <RequireVerification needsBankRegistered>
            <RequirePermission permissionFeature={PermissionFeature.Withdraw}>
              <Transfer />
            </RequirePermission>
          </RequireVerification>
        )}
      />

      <Route exact path={ROUTES.WITHDRAWS}>
        <Suspense fallback={null}>
          <Withdraws />
        </Suspense>
      </Route>

      <Route exact path={ROUTES.BANK_ACCOUNT_CONFIG}>
        <Suspense fallback={null}>
          <BankAccountRegistration bankAccountValidator={clabeValidator} />
        </Suspense>
      </Route>

      <Route
        exact
        path={`${ROUTES.REFUND}/:orderId`}
        render={() => (
          <RequireRole role={Roles.REFUND}>
            <Refund />
          </RequireRole>
        )}
      />

      <Route exact path={ROUTES.CONFIG}>
        <Suspense fallback={null}>
          <Configuration />
        </Suspense>
      </Route>

      <Route exact path={ROUTES.INSTALLMENTS}>
        <Suspense fallback={null}>
          <Installments />
        </Suspense>
      </Route>

      <Route exact path={ROUTES.TRANSACTIONS_REPORT}>
        <Suspense fallback={null}>
          <TransactionsReport />
        </Suspense>
      </Route>

      <Route exact path={ROUTES.LANDING}>
        <Suspense fallback={null}>
          <Landing />
        </Suspense>
      </Route>

      <Route path="*" render={() => <Redirect to={ROUTES.HOME} />} />
    </Switch>
  );

  return <>{storeInfo.active ? activeStoresRoutes : <InactiveStoreRoutes />}</>;
}

export default MexicoRoutes;
