import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';
import { Box, Button, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { UndoIcon } from '@nimbus-ds/icons';
import { CalendarIcon } from '@nimbus-ds/icons';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile, useStoreInfo } from 'commons/hooks';
import paymentLinkService, {
  PaymentLinkRefundArgs,
} from 'commons/services/paymentLink/paymentLinkService';
import { PaymentLinkDetailsType } from 'commons/types/PaymentLinkDetailsType';
import { useTranslationWithPrefix } from 'commons/utils';
import StatusTag, {
  TypeTagV2,
} from 'domains/Brazil/commons/components/StatusTagV2';
import { isRefundableStatus } from 'domains/Brazil/commons/utils/status';
import { AlertRefund } from 'domains/Brazil/Refund/components/AlertRefund/AlertRefund';
import ConfirmationModal from './ConfirmationModal';
import SkeletonHandler from './Skeleton';

interface Props {
  details: PaymentLinkDetailsType | undefined;
  isRefundPartialEnabled: boolean;
}

interface CustomAlertProps {
  title: string;
  message: string;
  linkTitle?: string;
  linkUrl?: string;
  appearance:
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'neutral'
    | undefined;
  testid: string;
}

function amplitudeTagMapper(status?: string) {
  switch (status) {
    case TypeTagV2.Paid:
      return 'nuvempago_payment_link_approved_detail_refund_button_click';
    case TypeTagV2.PartiallyRefunded:
      return 'nuvempago_payment_link_partial_refunded_detail_refund_button_click';
  }
}

function renderPtBrDate(date: string) {
  // eslint-disable-next-line max-len
  return new Date(date).toLocaleDateString('pt-br', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export default function HeaderBox(props: Props): JSX.Element {
  const isMobile = useIsMobile();
  const { t } = useTranslationWithPrefix('paymentLink');
  const { t: refundT } = useTranslationWithPrefix('refund');
  const history = useHistory();

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleOpenConfirmation = () =>
    setOpenConfirmation((prevState) => !prevState);

  const { storeInfo } = useStoreInfo();
  const [isShowAlert, setIsShowAlert] = useState(false);

  // eslint-disable-next-line max-len
  const [customAlertProps, setCustomAlertProps] = useState<CustomAlertProps>({
    title: '',
    message: '',
    linkTitle: '',
    appearance: undefined,
    testid: '',
  });

  const navigateToRefundDetail = () => {
    const referenceUUID = props.details?.referenceUUID;

    const tag = amplitudeTagMapper(props.details?.status);
    if (tag) track(tag);

    if (referenceUUID) {
      history.push(`${ROUTES.PAYMENT_LINK_REFUND}/${referenceUUID}`);
    }
  };

  function renderRefundButton(status = '') {
    if (!isRefundableStatus(status)) return <></>;
    if (isMobile) {
      return (
        <Link textDecoration="none">
          <Text
            fontSize="base"
            color="primary-textHigh"
            data-testid="button_test_mobile"
            onClick={
              props.isRefundPartialEnabled
                ? navigateToRefundDetail
                : handleOpenConfirmation
            }
            as="p"
          >
            {t('refund.actRefund')}
          </Text>
        </Link>
      );
    } else {
      return (
        <Button
          onClick={
            props.isRefundPartialEnabled
              ? navigateToRefundDetail
              : handleOpenConfirmation
          }
          data-testid="button_test_desktop"
        >
          <UndoIcon color="primary-textLow" size={16} />
          {t('refund.actRefund')}
        </Button>
      );
    }
  }

  function renderLinkNumber() {
    const formattedLinkNumber = String(props.details?.linkNumber).padStart(
      3,
      '0',
    );
    return (
      <Title color="primary-textHigh">
        {t('details.header.title', { linkNumber: formattedLinkNumber })}
      </Title>
    );
  }

  const refundTotal = useCallback(async () => {
    try {
      const amount = props.details?.payment.paid ?? 0;
      const referenceUUID = props.details?.referenceUUID ?? '';
      if (!(await paymentLinkService.validateAmount(amount))) {
        setCustomAlertProps({
          title: refundT('alert.not_enough_balance.title'),
          message: refundT('alert.not_enough_balance.message'),
          linkTitle: refundT('alert.not_enough_balance.title_link'),
          appearance: 'danger',
          testid: 'RefundTotal-Not-Enough-Balance-Alert',
        });
        setIsShowAlert(true);
        setOpenConfirmation(false);
        return;
      }

      const centsToDecimal = Number((amount / 100).toFixed(2));

      const args: PaymentLinkRefundArgs = {
        referenceUUID,
        amount: { value: centsToDecimal, currency: storeInfo.currency },
      };

      await paymentLinkService.refund(args);
      setCustomAlertProps({
        title: refundT('alert.success.title'),
        message: refundT('alert.success.message'),
        appearance: 'success',
        testid: 'PaymentLink-RefundTotal-Success-Alert',
      });
      setIsShowAlert(true);
      setOpenConfirmation(false);
    } catch (error: any) {
      setCustomAlertProps({
        title: refundT('alert.generic.title'),
        message: refundT('alert.generic.message'),
        appearance: 'danger',
        testid: 'PaymentLink-RefundTotal-Generic-Alert',
      });
      setIsShowAlert(true);
      setOpenConfirmation(false);
      throw error;
    }
  }, [
    props.details?.payment.paid,
    props.details?.referenceUUID,
    refundT,
    storeInfo.currency,
  ]);

  const renderAlert = () => (
    <AlertRefund
      isShowAlert={isShowAlert}
      title={customAlertProps.title}
      message={customAlertProps.message}
      linkTitle={customAlertProps.linkTitle}
      linkUrl={customAlertProps?.linkUrl}
      appearance={customAlertProps.appearance}
      handleAlertCloseClick={handleAlertCloseClick}
      testid={customAlertProps.testid}
    />
  );

  const handleAlertCloseClick = useCallback(() => {
    setIsShowAlert(false);
  }, []);

  return (
    <Box borderColor="neutral-interactive">
      <ConfirmationModal
        openConfirmationModal={openConfirmation}
        handleOpenConfirmation={handleOpenConfirmation}
        amount={props.details?.payment.paid ?? 0}
        actionCallback={refundTotal}
      />
      <Box display="grid" gap="2-5">
        <Box
          display="grid"
          gridTemplateColumns="max-content max-content"
          justifyContent="space-between"
        >
          <SkeletonHandler
            skeletonComponent={<Title.Skeleton />}
            trigger={!props.details?.linkNumber}
          >
            {renderLinkNumber()}
          </SkeletonHandler>
          <Box justifyContent="flex-end">
            {renderRefundButton(props.details?.status)}
          </Box>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="1fr"
          justifyContent="space-between"
        >
          <Box marginBottom={'4'} justifyContent="flex-end">
            <StatusTag type={props.details?.status} />
          </Box>
          <Box gridTemplateColumns="auto 1fr" display="grid">
            <SkeletonHandler
              trigger={!props?.details}
              skeletonComponent={<Text.Skeleton width="100%" />}
            >
              <Icon source={<CalendarIcon />} />
              <Box margin={'1'}>
                <Text
                  fontSize="caption"
                  textAlign="left"
                  color="neutral-textLow"
                >
                  {props.details && renderPtBrDate(props.details?.createdAt)}
                </Text>
              </Box>
            </SkeletonHandler>
          </Box>
          {renderAlert()}
        </Box>
      </Box>
    </Box>
  );
}
