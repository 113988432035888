const transactionStatusLabelMap = {
  waiting: 'authorized,pending,fraud_analysis_approved',
  approved: 'paid',
  refund: 'refunded,partially_refunded',
  rejected: 'failed',
  chargebacked: 'chargebacked,chargeback_dispute',
  expired: 'expired',
};

export default transactionStatusLabelMap;
