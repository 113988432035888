import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box, Button } from '@nimbus-ds/components';
import { useMutation, useQuery } from '@tanstack/react-query';
import AppLayout from 'App/components/AppLayout';
import { ROUTES } from 'App/Routes/constants';
import { AxiosError } from 'axios';
import { ErrorMessage } from 'commons/components';
import { useIsMobile } from 'commons/hooks';
import { refundService } from 'commons/services/refundService';
import { MoneyObjectInterface } from 'commons/types';
import { FailureCode, useTranslationWithPrefix } from 'commons/utils';
import CancelledAlert from './CancelledAlert/CancelledAlert';
import ErrorAlert from './ErrorAlert/ErrorAlert';
import RefundModal from './RefundModal/RefundModal';
import ClientCard from './ClientCard';
import OrderDetailCard from './OrderDetailCard';
import RefundCard from './RefundCard';
import RefundSkeleton from './RefundSkeleton';

interface RefundParams {
  orderId: string;
}

function Refund(): JSX.Element | null {
  const history = useHistory();
  const isMobile = useIsMobile();
  const { t } = useTranslationWithPrefix('refund');
  const { orderId } = useParams<RefundParams>();
  const [amountToRefund, setAmountToRefund] = useState<number>(0);
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [errorInput, setErrorInput] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const refundDetailsQuery = useQuery(
    ['refundDetails', orderId],
    () => refundService.getRefundDetails(orderId),
    {
      retry: false,
    },
  );
  const postRefund = useMutation({
    mutationFn: (amount: MoneyObjectInterface) =>
      refundService.refund(orderId, amount),
    onSuccess: () => {
      history.push(`${ROUTES.TRANSACTION_DETAILS}/${orderId}`);
    },
    onError: (error) => {
      if (
        (error as AxiosError<any>).response?.data?.details ===
        FailureCode.InsufficientAccountBalance
      ) {
        setRefundModalOpen(false);
        setShowErrorAlert(true);
      } else {
        const errorDetail = (error as AxiosError<any>).response?.data?.details;
        const hasPendingRefund =
          errorDetail === FailureCode.PendingRefundInProgress ||
          errorDetail === FailureCode.RefundAlreadyInProcess;

        history.push(`${ROUTES.TRANSACTION_DETAILS}/${orderId}`, {
          showRefundAlert: true,
          refundError: !hasPendingRefund,
          pendingRefundWarning: hasPendingRefund,
        });
      }
    },
  });

  if (refundDetailsQuery.isLoading) {
    return <RefundSkeleton />;
  }
  if (refundDetailsQuery.isError) {
    return (
      <AppLayout title={t('title')}>
        <Box
          display="flex"
          alignItems="stretch"
          justifyContent="flex-end"
          flexDirection="column"
        >
          <ErrorMessage />
        </Box>
      </AppLayout>
    );
  }
  if (!refundDetailsQuery.isSuccess) {
    return null;
  }

  const refundDetailsData = refundDetailsQuery.data;

  const goBack = () => history.goBack();

  const openRefundModal = () => setRefundModalOpen(true);

  const closeRefundModal = () => setRefundModalOpen(false);

  const closeErrorAlert = () => setShowErrorAlert(false);

  const doRefund = () => {
    if (refundDetailsData.orderCancelled) {
      track('refund-nuvempago-canceled-order');
    }

    postRefund.mutate({
      value: amountToRefund,
      currency: refundDetailsData.prices.total.currency,
    });
  };

  const rightCards = (
    <>
      <ClientCard
        name={refundDetailsData.consumer.name}
        email={refundDetailsData.consumer.email}
      />
      <RefundCard
        remainingAmountToRefund={refundDetailsData.remainingAmountToRefund}
        amountToRefund={amountToRefund}
        hasAlreadyRefunded={
          !!refundDetailsData.prices.totalRefundedAmount.value
        }
        setAmountToRefund={setAmountToRefund}
        errorInput={errorInput}
        setErrorInput={setErrorInput}
      />
      <Box display="flex" justifyContent="flex-end" gap="2">
        <Button appearance="neutral" onClick={goBack}>
          {t('cancelButton')}
        </Button>
        <Button
          appearance="primary"
          disabled={amountToRefund < 1 || !!errorInput}
          onClick={openRefundModal}
        >
          {t('refundButton')}
        </Button>
      </Box>
    </>
  );

  const DesktopLayout = (
    <Box
      gap="4"
      display="flex"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Box width="100%">
        <OrderDetailCard
          orderId={refundDetailsData.orderNumber}
          prices={refundDetailsData.prices}
          products={refundDetailsData.productDetails}
          coupon={refundDetailsData.coupon}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="4" width="100%">
        {rightCards}
      </Box>
    </Box>
  );

  const MobileLayout = (
    <Box display="flex" flexDirection="column" gap="4" width="100%">
      <OrderDetailCard
        orderId={refundDetailsData.orderNumber}
        prices={refundDetailsData.prices}
        products={refundDetailsData.productDetails}
        coupon={refundDetailsData.coupon}
      />
      {rightCards}
    </Box>
  );

  return (
    <AppLayout
      title={t('title')}
      backLinkPath={`${ROUTES.TRANSACTION_DETAILS}/${orderId}`}
    >
      <RefundModal
        onDismiss={closeRefundModal}
        onRefund={doRefund}
        open={refundModalOpen}
        isTotalRefund={
          amountToRefund === refundDetailsData.remainingAmountToRefund.value
        }
        amountToRefund={amountToRefund}
        loading={postRefund.isLoading}
      />
      <Box display="flex" flexDirection="column" gap="4">
        <ErrorAlert show={showErrorAlert} onRemove={closeErrorAlert} />
        <CancelledAlert show={refundDetailsData.orderCancelled} />
        {isMobile ? MobileLayout : DesktopLayout}
      </Box>
    </AppLayout>
  );
}

export default Refund;
