export const useMexicanBanks = (): {
  getByBankCode: (bankCode: string) => string;
} => {
  const mexicanBanks: Record<string, string> = {
    '000': 'TEST', // Used by stripe for testing purposes
    '001': 'BANXICO',
    '002': 'BANAMEX',
    '006': 'BANCOMEXT',
    '009': 'BANOBRAS',
    '012': 'BBVA_MEXICO',
    '014': 'SANTANDER',
    '019': 'BANJERCITO',
    '021': 'HSBC',
    '030': 'BAJIO',
    '036': 'INBURSA',
    '042': 'MIFEL',
    '044': 'SCOTIABANK',
    '058': 'BANREGIO',
    '059': 'INVEX',
    '060': 'BANSI',
    '062': 'AFIRME',
    '072': 'BANORTE',
    '106': 'BANK_OF_AMERICA',
    '108': 'MUFG',
    '110': 'JP_MORGAN',
    '112': 'BMONEX',
    '113': 'VE_POR_MAS',
    '124': 'CITI_MEXICO',
    '127': 'AZTECA',
    '128': 'AUTOFIN',
    '129': 'BARCLAYS',
    '130': 'COMPARTAMOS',
    '132': 'MULTIVA_BANCO',
    '133': 'ACTINVER',
    '135': 'NAFIN',
    '136': 'INTERCAM_BANCO',
    '137': 'BANCOPPEL',
    '138': 'ABC_CAPITAL',
    '140': 'CONSUBANCO',
    '141': 'VOLKSWAGEN',
    '143': 'CIBANCO',
    '145': 'BBASE',
    '147': 'BANKAOOL',
    '148': 'PAGATODO',
    '150': 'INMOBILIARIO',
    '151': 'DONDE',
    '152': 'BANCREA',
    '154': 'BANCO_COVALTO',
    '155': 'ICBC',
    '156': 'SABADELL',
    '157': 'SHINHAN',
    '158': 'MIZUHO_BANK',
    '159': 'BANK_OF_CHINA',
    '160': 'BANCO_S3',
    '166': 'BaBien',
    '168': 'HIPOTECARIA_FED',
    '600': 'MONEXCB',
    '601': 'GBM',
    '602': 'MASARI',
    '605': 'VALUE',
    '608': 'VECTOR',
    '616': 'FINAMEX',
    '617': 'VALMEX',
    '620': 'PROFUTURO',
    '630': 'CB_INTERCAM',
    '631': 'CI_BOLSA',
    '634': 'FINCOMUN',
    '638': 'NU_MEXICO',
    '646': 'STP',
    '652': 'CREDICAPITAL',
    '653': 'KUSPIT',
    '656': 'UNAGRA',
    '659': 'ASP_INTEGRA_OPC',
    '661': 'KLAR',
    '670': 'LIBERTAD',
    '677': 'CAJA_POP_MEXICA',
    '680': 'CRISTOBAL_COLON',
    '683': 'CAJA_TELEFONIST',
    '684': 'TRANSFER',
    '685': 'FONDO_FIRA',
    '688': 'CREDICLUB',
    '689': 'FOMPED',
    '699': 'FONDEADORA',
    '703': 'TESORED',
    '706': 'ARCUS',
    '710': 'NVIO',
    '722': 'Mercado_Pago_W',
    '723': 'Cuenca',
    '728': 'SPIN_BY_OXXO',
    '901': 'CLS',
    '902': 'INDEVAL',
    '903': 'CoDi_Valida',
  };

  const getByBankCode = (bankCode: string): string => {
    return mexicanBanks[bankCode] || '';
  };

  return { getByBankCode };
};
