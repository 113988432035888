import { TFunction } from 'react-i18next';
import { IconProps } from '@nimbus-ds/icons';
import { ExclamationCircleIcon, MoneyIcon, UndoIcon } from '@nimbus-ds/icons';
import { ChargebackType, TransactionStatusType } from 'commons/types';

type appearanceTypes =
  | 'danger'
  | 'primary'
  | 'secondary'
  | 'default'
  | 'warning'
  | 'success'
  | undefined;

interface transactionLabelPropsValue {
  id: string;
  label: string;
  icon: React.FC<IconProps>;
  appearance: appearanceTypes;
}

const transactionLabelProps = (
  statusType: TransactionStatusType,
  t: TFunction<string, undefined>,
  labelKey = '1',
  rejectedText = 'rejected',
  chargeback?: ChargebackType,
): transactionLabelPropsValue => {
  const approvedLabel = {
    label: t('home.status.approved'),
    icon: MoneyIcon,
    appearance: 'success' as appearanceTypes,
  };

  const rejectedLabel = {
    label: t(`home.status.${rejectedText}`),
    icon: MoneyIcon,
    appearance: 'danger' as appearanceTypes,
  };

  const waitingLabel = {
    label: t('home.status.waiting'),
    icon: MoneyIcon,
    appearance: 'warning' as appearanceTypes,
  };

  const refundLabel = {
    label: t('home.status.refund'),
    icon: UndoIcon,
    appearance: 'default' as appearanceTypes,
  };

  const partialRefundLabel = {
    label: t('home.status.partial_refund'),
    icon: UndoIcon,
    appearance: 'default' as appearanceTypes,
  };

  const chargebackLabel = {
    label: t('home.status.chargebacked'),
    icon: ExclamationCircleIcon,
    appearance: 'secondary' as appearanceTypes,
  };

  const chargebackV2Label = {
    label: t(`home.status.chargebackV2.${chargeback?.status}`),
    icon: MoneyIcon,
    appearance: 'primary' as appearanceTypes,
  };

  const expiredLabel = {
    label: t(`home.status.expired`),
    icon: MoneyIcon,
    appearance: 'danger' as appearanceTypes,
  };

  const labels = {
    authorized: waitingLabel,
    pending: waitingLabel,
    fraud_analysis_approved: waitingLabel,
    paid: approvedLabel,
    refunded: refundLabel,
    partially_refunded: partialRefundLabel,
    failed: rejectedLabel,
    chargebacked: chargebackLabel,
    chargeback_dispute: chargebackLabel,
    expired: expiredLabel,
  };

  return chargeback
    ? { id: `id-${labelKey}`, ...chargebackV2Label }
    : {
        id: `id-${labelKey}`,
        ...labels[statusType],
      };
};

export default transactionLabelProps;
