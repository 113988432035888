import React, { useEffect } from 'react';
import { Icon, Label } from '@nimbus-ds/components';
import { CalendarIcon } from '@nimbus-ds/icons';
import {
  getRelativeDateFromToday,
  todayDate,
  useTranslationWithPrefix,
} from 'commons/utils';
import DateRangeFilterEnum from 'domains/Brazil/commons/types/DateRangeFilterEnum';
import DateRadioFilter from 'domains/Brazil/Dashboard/components/filter/DateRadioFilter';
import DateRangeFilter from './DateRangeFilterV2';

const SELECT_OPTIONS = Object.values(DateRangeFilterEnum);

interface DateFilterInterface {
  dateRangeOption: DateRangeFilterEnum;
  setRangeDateOption: React.Dispatch<React.SetStateAction<DateRangeFilterEnum>>;
  fromDate: string | undefined;
  setFromDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  toDate: string | undefined;
  setToDate: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function DateFilterV2({
  dateRangeOption,
  setRangeDateOption,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}: DateFilterInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('home');

  useEffect(() => {
    if (dateRangeOption === DateRangeFilterEnum.SEVEN_DAYS) {
      setFromDate(getRelativeDateFromToday(-7));
      setToDate(todayDate());
    } else if (dateRangeOption === DateRangeFilterEnum.THIRTY_DAYS) {
      setFromDate(getRelativeDateFromToday(-30));
      setToDate(todayDate());
    } else if (dateRangeOption === DateRangeFilterEnum.TODAY) {
      setFromDate(todayDate());
      setToDate(todayDate());
    } else if (dateRangeOption === DateRangeFilterEnum.YESTERDAY) {
      const yesterday = getRelativeDateFromToday(-1);
      setFromDate(yesterday);
      setToDate(yesterday);
    } else if (dateRangeOption === DateRangeFilterEnum.ALL) {
      setFromDate(undefined);
      setToDate(undefined);
    }
  }, [dateRangeOption, setFromDate, setToDate]);

  return (
    <>
      <Label>
        <Icon source={<CalendarIcon />} />
        {t('transactionsSearch.date')}
      </Label>
      <DateRadioFilter
        options={SELECT_OPTIONS}
        dateRangeOption={dateRangeOption}
        setRangeDateOption={setRangeDateOption}
      />
      {dateRangeOption === 'custom' && (
        <DateRangeFilter
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
      )}
    </>
  );
}

export default DateFilterV2;
