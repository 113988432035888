import { TypeTagV2 } from 'domains/Brazil/commons/components/StatusTagV2';
import { ChargesDashboardStatus, FailureReason } from './dashboard.service';

const statusMap = new Map<ChargesDashboardStatus, TypeTagV2>([
  [ChargesDashboardStatus.Paid, TypeTagV2.Paid],
  [ChargesDashboardStatus.Refunded, TypeTagV2.Refunded],
  [ChargesDashboardStatus.PartiallyRefunded, TypeTagV2.PartiallyRefunded],
  [ChargesDashboardStatus.Chargebacked, TypeTagV2.Contested],
  [ChargesDashboardStatus.ChargebackDispute, TypeTagV2.Contested],
  [ChargesDashboardStatus.Failed, TypeTagV2.Failed],
  [ChargesDashboardStatus.Pending, TypeTagV2.Pending],
  [ChargesDashboardStatus.Expired, TypeTagV2.Expired],
  [ChargesDashboardStatus.Authorized, TypeTagV2.Authorized],
  [ChargesDashboardStatus.FraudAnalysisApproved, TypeTagV2.Authorized],
]);

export function getStatusTagCode(
  status: ChargesDashboardStatus,
  failureReason?: FailureReason,
) {
  let tagCode = statusMap.get(status) ?? TypeTagV2.NotDefined;

  if (status === ChargesDashboardStatus.Failed) {
    tagCode =
      failureReason === FailureReason.rejectedBySecurity
        ? TypeTagV2.RejectedBySecurity
        : TypeTagV2.Failed;
  }

  return tagCode;
}
