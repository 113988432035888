import React from 'react';
import { Box, Icon, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { FormattedMultilineText, MultilineText } from 'commons/components';
import { useIsMobile } from 'commons/hooks';
import { PayInfoType, TransactionStatusType } from 'commons/types';
import { formatSpanishLongDate, useTranslationWithPrefix } from 'commons/utils';

interface WireTransferMethodProps {
  payInfo?: PayInfoType;
  status?: TransactionStatusType;
}

export default function WireTransferMethod({
  payInfo,
  status,
}: WireTransferMethodProps) {
  const { t } = useTranslationWithPrefix(
    'transactionDetails.paymentMethods.wireTransfer',
  );

  const shouldShowCVU = status === 'pending' || status === 'failed';
  const isMobile = useIsMobile();
  const shouldShowExpired = status === 'expired';

  const Expired =
    shouldShowExpired && payInfo?.expiresAt ? (
      <>
        <Box
          gap="4"
          display="flex"
          flexDirection="column"
          borderWidthX="none"
          borderTopWidth="none"
          borderBottomWidth="1"
          borderColor="neutral-surfaceHighlight"
          borderStyle="solid"
          paddingBottom="4"
        >
          <Box>
            <Text>{t('CVUExpired')}</Text>
            <Text fontWeight="medium">{payInfo.code?.slice(-6)}</Text>
          </Box>
          <Box>
            <Text>{t('beneficiary')}</Text>
            <Text fontWeight="medium">{payInfo.bankAccount?.holderName}</Text>
          </Box>
        </Box>
        <Box>
          <Text fontWeight="medium">
            {t('expiredDate', {
              expiresAt: formatSpanishLongDate(payInfo.expiresAt),
              interpolation: { escapeValue: false },
            })}
          </Text>
        </Box>
        <Text>{t('expiredDescription')}</Text>
        {isMobile && (
          <Box>
            <Link href={t('transferHelpLink.href')} appearance="primary" as="a">
              {t('transferHelpLink.text')}
              <Icon color="currentColor" source={<ExternalLinkIcon />} />
            </Link>
          </Box>
        )}
      </>
    ) : null;

  const CVU =
    shouldShowCVU && payInfo?.expiresAt ? (
      <>
        <Box
          gap="4"
          display="flex"
          flexDirection="column"
          borderWidthX="none"
          borderTopWidth="none"
          borderBottomWidth="1"
          borderColor="neutral-surfaceHighlight"
          borderStyle="solid"
          paddingBottom="4"
        >
          <Box>
            <MultilineText>{t('CVU', { cvu: payInfo.code })}</MultilineText>
          </Box>
          <Box>
            <MultilineText>{t('beneficiary')}</MultilineText>
            <Text fontWeight="medium">{payInfo.bankAccount?.holderName}</Text>
          </Box>
        </Box>

        <FormattedMultilineText>
          {t('expiresAt', {
            expiresAt: formatSpanishLongDate(payInfo.expiresAt),
          })}
        </FormattedMultilineText>
      </>
    ) : null;

  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Text>{t('description')}</Text>
      {CVU}
      {Expired}
    </Box>
  );
}
