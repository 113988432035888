import React from 'react';
import { Box, Card, Input, Text } from '@nimbus-ds/components';
import { TextByCountry } from 'commons/components';
import { getCuitOrCuilLabel, useTranslationWithPrefix } from 'commons/utils';
import BankAccountInputsSkeleton from './BankAccountInputsSkeleton';

interface BankAccountInputProps {
  isRegistered: boolean;
  fiscalNumber: string;
  beneficiaryName: string;
  accountNumber: string;
  handleChangeAccountNumber: React.ChangeEventHandler<HTMLInputElement>;
  handleChangeBeneficiaryName: React.ChangeEventHandler<HTMLInputElement>;
  bank: string;
  error: {
    beneficiaryName: string;
    accountNumber: string;
    bankName: string;
  };
}

function BankAccountInputs({
  fiscalNumber,
  beneficiaryName,
  accountNumber,
  handleChangeAccountNumber,
  handleChangeBeneficiaryName,
  bank,
  error,
}: BankAccountInputProps) {
  const { t } = useTranslationWithPrefix('bankAccountRegistration');

  return (
    <Card>
      <Box display="flex" flexDirection="column" gap="4">
        <Box display="flex" flexDirection="column" gap="1">
          <Text fontSize="caption">
            <TextByCountry
              AR={`${getCuitOrCuilLabel(fiscalNumber)}`}
              BR={`${getCuitOrCuilLabel(fiscalNumber)}`}
              MX={`${t('rfc')}`}
            />
          </Text>
          <Input
            aria-label={'document-input'}
            name={'document-input'}
            value={fiscalNumber}
            disabled
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="1">
          <Text fontSize="caption">{t('beneficiaryName')}</Text>
          <Input
            onChange={handleChangeBeneficiaryName}
            aria-label={'beneficiaryName'}
            name={'beneficiaryName'}
            value={beneficiaryName}
            type="text"
            appearance={error['beneficiaryName'] ? 'danger' : 'neutral'}
          />
          {error['beneficiaryName'] ? (
            <Text fontSize="caption" color="danger-interactive">
              {error['beneficiaryName']}
            </Text>
          ) : null}
        </Box>
        <Box display="flex" flexDirection="column" gap="1">
          <Text fontSize="caption">{t('accountNumber')}</Text>
          <Input
            onChange={handleChangeAccountNumber}
            aria-label={t('accountNumber')}
            name={t('accountNumber')}
            value={accountNumber}
            type="number"
            appearance={error['accountNumber'] ? 'danger' : 'neutral'}
          />
          {error['accountNumber'] ? (
            <Text fontSize="caption" color="danger-interactive">
              {error['accountNumber']}
            </Text>
          ) : null}
        </Box>
        {bank && (
          <Box display="flex" flexDirection="column" gap="1">
            <Text fontSize="caption">{t('bank')}</Text>
            <Input
              aria-label={'bank-input'}
              name={'bank-input'}
              value={bank}
              disabled
              appearance={error['bankName'] ? 'danger' : 'neutral'}
              maxLength={45}
            />
            {error['bankName'] ? (
              <Text fontSize="caption" color="danger-interactive">
                {error['bankName']}
              </Text>
            ) : null}
          </Box>
        )}
      </Box>
    </Card>
  );
}
BankAccountInputs.Skeleton = BankAccountInputsSkeleton;
export default BankAccountInputs;
