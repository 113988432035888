import React from 'react';
import { Box, Button } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import ClientCard from './ClientCard';
import OrderDetailCard from './OrderDetailCard';
import RefundCard from './RefundCard';

function RefundSkeleton(): JSX.Element | null {
  const isMobile = useIsMobile();
  const { t } = useTranslationWithPrefix('refund');

  const DesktopLayout = (
    <Box
      gap="4"
      display="flex"
      justifyContent="space-between"
      alignItems="stretch"
      data-testid="refund-skeleton"
    >
      <Box width="100%">
        <OrderDetailCard.Skeleton />
      </Box>
      <Box display="flex" flexDirection="column" gap="4" width="100%">
        <ClientCard.Skeleton />
        <RefundCard.Skeleton />
        <Box display="flex" justifyContent="flex-end" gap="2">
          <Button.Skeleton />
          <Button.Skeleton />
        </Box>
      </Box>
    </Box>
  );

  const MobileLayout = (
    <Box
      display="flex"
      flexDirection="column"
      gap="4"
      width="100%"
      data-testid="refund-skeleton"
    >
      <OrderDetailCard.Skeleton />
      <ClientCard.Skeleton />
      <RefundCard.Skeleton />
      <Box display="flex" justifyContent="flex-end" gap="2">
        <Button.Skeleton />
        <Button.Skeleton />
      </Box>
    </Box>
  );

  return (
    <AppLayout title={t('title')}>
      {isMobile ? MobileLayout : DesktopLayout}
    </AppLayout>
  );
}

export default RefundSkeleton;
