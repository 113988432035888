import React, { useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Card, Icon, Title, Toggle, Tooltip } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { ErrorMessage } from 'commons/components';
import { InstallmentV2Type } from 'commons/types';
import { formatPercentage, useTranslationWithPrefix } from 'commons/utils';
import InstallmentsCheckbox from 'domains/Installments/shared/InstallmentsCheckbox/InstallmentsCheckbox';
import { InstallmentSectionInactive } from '../../shared/InstallmentSectionInactive';
import {
  ResponsibleStatus,
  SelectedInstallments,
} from '../../useInstallmentsV2';

export interface WithoutInterestMxInterface {
  installments?: SelectedInstallments;
  setInstallments: (installments: SelectedInstallments) => void;
  configDisabled: boolean;
  hasError: boolean;
  openSwitchModal: () => void;
  status: ResponsibleStatus;
}

export function WithoutInterestMx({
  installments,
  setInstallments,
  configDisabled,
  hasError,
  openSwitchModal,
  status,
}: WithoutInterestMxInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('installments.withoutInterest');
  const areInstallmentsEnabled =
    !!installments && installments.withoutInterest.some((i) => i.enabled);
  const [isToggleMSIOn, setIsToggleMSIOn] = useState<boolean>(
    areInstallmentsEnabled,
  );

  const handleChangeToggleMSI = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    track('pago-nube-msi');
    setIsToggleMSIOn(event.target.checked);
    if (
      !event.target.checked &&
      installments &&
      installments.withoutInterest?.length > 0
    ) {
      const newInstallments = { ...installments };
      const installmentsDisabled = newInstallments.withoutInterest.map((i) => ({
        ...i,
        enabled: false,
      }));
      setInstallments({ withoutInterest: installmentsDisabled });
    }
  };

  const installmentLabel = (installment: InstallmentV2Type) => {
    if (installment.quantity === 1) return t('installments.one');

    return installment.interest
      ? t('installments.many', {
          quantity: installment.quantity,
          rate: formatPercentage(installment.interest),
        })
      : t('installments.many_unknown_interest', {
          quantity: installment.quantity,
        });
  };

  const renderInstallments = (
    name: string,
    installmentList: InstallmentV2Type[],
    forceDisable?: boolean,
  ) => {
    return (
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        gap="2"
        width="100%"
      >
        <Box
          flexDirection="column"
          display="flex"
          width="100%"
          alignItems="flex-start"
          gap="2-5"
        >
          {installmentList.map((installment, id) => (
            <Card key={`installment-${name}-${id}`}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap="2"
              >
                <InstallmentsCheckbox
                  configDisabled={configDisabled}
                  name={name}
                  installment={installment}
                  installmentList={installmentList}
                  installments={installments}
                  installmentLabel={installmentLabel}
                  forceDisable={forceDisable}
                  setInstallments={setInstallments}
                />
              </Box>
            </Card>
          ))}
        </Box>
      </Box>
    );
  };

  const InstallmentsSection = (forceDisable?: boolean) => {
    return (
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        gap="4"
      >
        {renderInstallments(
          'withoutInterest',
          installments?.withoutInterest || [],
          forceDisable,
        )}
      </Box>
    );
  };

  if (hasError)
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Title as="h4">{t('title')}</Title>
        <ErrorMessage />
      </Box>
    );

  if (status === 'merchant_active')
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap="2-5">
            <Title as="h4">{t('title')}</Title>
            <Tooltip arrow content={t('infoTooltip')} position="right">
              <Icon
                color="primary-textLow"
                source={<InfoCircleIcon size="small" />}
              />
            </Tooltip>
          </Box>
          <Toggle
            name="without-interest"
            data-testid="without-interest-toggle"
            checked={isToggleMSIOn}
            disabled={installments && installments?.withoutInterest?.length < 1}
            onChange={handleChangeToggleMSI}
          />
        </Box>
        {InstallmentsSection(!isToggleMSIOn)}
      </Box>
    );

  return (
    <InstallmentSectionInactive
      translationPrefix="installments.withoutInterest"
      openSwitchModal={openSwitchModal}
    />
  );
}
