import React from 'react';
import { Icon, Tag, Text } from '@nimbus-ds/components';
import { MoneyIcon } from '@nimbus-ds/icons';
import { useTranslationWithPrefix } from 'commons/utils';

type TagContent = {
  appearance: React.ComponentProps<typeof Tag>['appearance'];
  message: string;
};

export enum TypeTagV2 {
  Paid = 'PAID',
  Refunded = 'REFUNDED',
  PartiallyRefunded = 'PARTIALLYREFUNDED',
  Contested = 'CHARGEBACKED',
  RejectedBySecurity = 'REJECTEDBYSECURITY',
  RejectedByEmissor = 'REJECTEDBYEMISSOR',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Authorized = 'AUTHORIZED',
  Expired = 'EXPIRED',
  NotDefined = 'UNKNOWN',
}

const typeTagMap = new Map<string, TagContent | null>([
  [TypeTagV2.Paid, { appearance: 'success', message: 'approved' }],
  [TypeTagV2.Refunded, { appearance: 'neutral', message: 'refunded' }],
  [
    TypeTagV2.PartiallyRefunded,
    { appearance: 'neutral', message: 'partiallyRefunded' },
  ],
  [TypeTagV2.Contested, { appearance: 'neutral', message: 'contested' }],
  [TypeTagV2.Expired, { appearance: 'neutral', message: 'expired' }],
  [
    TypeTagV2.RejectedBySecurity,
    { appearance: 'danger', message: 'rejectedBySecurity' },
  ],
  [
    TypeTagV2.RejectedByEmissor,
    { appearance: 'danger', message: 'rejectedByEmissor' },
  ],
  [TypeTagV2.Failed, { appearance: 'danger', message: 'rejectedByIssuer' }],
  [TypeTagV2.Pending, { appearance: 'warning', message: 'pending' }],
  [TypeTagV2.Authorized, { appearance: 'warning', message: 'inProcess' }],
  [TypeTagV2.NotDefined, null],
]);

interface StatusParams {
  type: TypeTagV2 | string | undefined;
}

function StatusTagV2({ type }: StatusParams): JSX.Element {
  const { t } = useTranslationWithPrefix('statusTag');

  const content = typeTagMap.get(type?.toUpperCase() ?? TypeTagV2.NotDefined);

  if (!content) return <></>;

  const { appearance, message } = content;

  return (
    <Tag appearance={appearance} data-testid="status-tag">
      <Icon color="currentColor" source={<MoneyIcon size={14} />} />
      <Text
        as="span"
        color="currentColor"
        fontSize="caption"
        fontWeight="regular"
        lineHeight="caption"
        textAlign="left"
      >
        {t(message)}
      </Text>
    </Tag>
  );
}

function Skeleton(): JSX.Element {
  return <Tag.Skeleton width="70%" height="20px" />;
}

StatusTagV2.Skeleton = Skeleton;

export default StatusTagV2;
