import appAxios from 'App/axios';
import axios from 'axios';
import {
  AddAccountDocumentArgs,
  Document,
  DocumentForUpload,
  DocumentSideAttachment,
  DocumentSolicitation,
  DocumentSolicitationsResponse,
  InterfaceSolicitation,
  PreSignedUrl,
} from 'domains/Brazil/Banking/IdentityValidation/utils/interfaces';
import { DocumentSide } from 'domains/Brazil/Banking/IdentityValidation/utils/types/DocumentSide.enum';
import { DocumentType } from 'domains/Brazil/Banking/IdentityValidation/utils/types/DocumentType.enum';
import { RecipientType } from 'domains/Brazil/commons/types/RecipientType.enum';

const fipHost = `${process.env.REACT_APP_BANKING_GATEWAY_URL}/identity`;

function extractFileExtension(file: File): string {
  const extension = file.type.split('/').pop();

  if (!extension) {
    throw new Error('Invalid file extension');
  }

  return extension;
}

async function uploadFileToS3(
  fileStream: File,
  { url, fields }: PreSignedUrl,
): Promise<void> {
  try {
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', fileStream);

    await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw error;
  }
}

async function obtainPresignedUrlForUpload(
  fileExtension: string,
): Promise<PreSignedUrl> {
  try {
    const response = await appAxios.post(`${fipHost}/v1/documents/url`, {
      extension: fileExtension,
    });

    const { url, fields } = response.data;
    return { url, fields };
  } catch (error) {
    throw error;
  }
}

async function addDocumentReference(
  reference: string,
  fileExtension: string,
  document: Document,
  recipientType?: string,
) {
  const payload = {
    personType: recipientType === 'COMPANY' ? 'LEGAL_PERSON' : 'NATURAL_PERSON',
    document: {
      type: document.documentType,
      references: [
        {
          extension: fileExtension,
          side: document.documentSide,
          correlation_id: reference,
        },
      ],
    },
  };
  await appAxios.put(`${fipHost}/v1/documents/references`, payload);
}

async function addAccountDocument({
  file,
  document,
  recipientType,
}: AddAccountDocumentArgs): Promise<void> {
  const fileExtension = extractFileExtension(file);

  const { url, fields } = await obtainPresignedUrlForUpload(fileExtension);

  await uploadFileToS3(file, { url, fields });

  const reference = `${url}${fields.key}`;
  await addDocumentReference(reference, fileExtension, document, recipientType);
}

async function uploadFiles(files: Map<DocumentSide, DocumentSideAttachment>) {
  const uploadedDocumentSides = new Map<DocumentSide, DocumentSideAttachment>();
  await Promise.all(
    Array.from(files).map(async ([key, side]) => {
      const fileExtension = extractFileExtension(side.file);
      const presignedUrl = await obtainPresignedUrlForUpload(fileExtension);

      await uploadFileToS3(side.file, presignedUrl);

      uploadedDocumentSides.set(key, {
        ...side,
        reference: `${presignedUrl.url}${presignedUrl.fields.key}`,
      });
    }),
  );

  return uploadedDocumentSides;
}

async function addAccountDocumentV2(
  recipientType: RecipientType,
  document: DocumentForUpload,
) {
  const personType =
    recipientType === 'COMPANY' ? 'LEGAL_PERSON' : 'NATURAL_PERSON';

  const uploadedDocumentSides = await uploadFiles(document.sides);

  const references = Array.from(uploadedDocumentSides).map(([, document]) => ({
    extension: extractFileExtension(document.file),
    side: document.side,
    correlation_id: document.reference,
  }));

  const payload = {
    personType,
    document: {
      type: document.type,
      references,
    },
  };

  await appAxios.put(`${fipHost}/v1/documents/references`, payload);
}

async function getDocumentSolicitations() {
  const { data } = await appAxios.get<DocumentSolicitationsResponse>(
    `${fipHost}/v1/solicitation`,
  );

  // TODO: Refactor this to an approach that works and identifies N partners
  const solicitations: DocumentSolicitation[] = [
    ...(data.person.documents || []),
    ...data.partners.flatMap((partner) => partner.documents || []),
    ...(data.company?.documents || []),
  ];

  const solicitationsMap = new Map<DocumentType, InterfaceSolicitation>();

  solicitations.forEach(({ type, side }: DocumentSolicitation) => {
    const document = solicitationsMap.get(type) || {
      type,
      sides: new Set(),
    };

    solicitationsMap.set(type, {
      ...document,
      sides: document.sides.add(side),
    });
  });

  return solicitationsMap;
}

export default {
  obtainPresignedUrlForUpload,
  uploadFileToS3,
  extractFileExtension,
  addAccountDocument,
  addDocumentReference,
  getDocumentSolicitations,
  addAccountDocumentV2,
  uploadFiles,
};
