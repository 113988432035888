import React from 'react';
import { Checkbox } from '@nimbus-ds/components';
import { InstallmentV2Type } from 'commons/types';
import { SelectedInstallments } from 'domains/Installments/useInstallmentsV2';
import { checkInstallment, couldBeEnabledIn } from '../utils';

interface Props {
  configDisabled: boolean;
  installment: InstallmentV2Type;
  installmentLabel: (installment: InstallmentV2Type) => string;
  installmentList: InstallmentV2Type[];
  name: string;
  setInstallments: (installments: SelectedInstallments) => void;
  forceDisable?: boolean;
  installments?: SelectedInstallments;
}

const InstallmentsCheckbox = ({
  configDisabled,
  installment,
  installmentLabel,
  installmentList,
  name,
  setInstallments,
  forceDisable,
  installments,
}: Props) => {
  return (
    <Checkbox
      label={installmentLabel(installment)}
      name={`${name}-${installment.quantity}`}
      checked={installment.enabled}
      onChange={(event) =>
        checkInstallment(
          name,
          installment.quantity,
          event.currentTarget.checked,
          installmentList,
          setInstallments,
          installments,
        )
      }
      disabled={
        forceDisable ||
        configDisabled ||
        !couldBeEnabledIn(name, installment.quantity, installments)
      }
    />
  );
};

export default InstallmentsCheckbox;
