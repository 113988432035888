import axios from 'App/axios';

export enum FeatureFlag {
  // PagoNube
  FS_PAGONUBE_TRANSFER_DISABLED = 'fs-pagonube-transfer-disabled',
  FS_PAGONUBE_QR_MODO_ENABLED = 'fs-pagonube-qr-modo-enable',

  // NuvemPago
  FS_NUVEMPAGO_REFUND_PARTIAL_ROLLOUT = 'fs-nuvempago-refund-partial-rollout',
  FS_NUVEMPAGO_WITHDRAW_ROLLOUT = 'fs-nuvempago-withdraw-rollout',
  FS_NUVEMPAGO_REFUND_PIX = 'fs-nuvempago-refund-pix',
  FS_NUVEMPAGO_DISCOUNT_LABEL = 'fs-nuvempago-discount-label',
  FS_NUVEMPAGO_REFUND_RECEIPT_ENABLE = 'fs-nuvempago-refund-receipt-enable',
  FS_NUVEMPAGO_PARTIAL_REFUND_PIX = 'fs-nuvempago-partial-refund-pix',
  FS_NUVEMPAGO_REFUND_RESTOCK = 'fs-nuvempago-refund-restock',
  FS_NUVEMPAGO_REFUND_RECEIPT_FRONTEND_PDF_EXPORT = 'fs-nuvempago-refund-receipt-frontend-pdf-export',
  FS_NUVEMPAGO_KYC_REJECT_NOTIFICATION_BY_COMMONS = 'fs-nuvempago-kyc-reject-notification-by-commons',
  FS_NUVEMPAGO_2FA_ENABLED = 'fs-nuvempago-2fa-enabled',
  FS_VALIDATE_USER_ROLE = 'fs-validate-user-role',
  FS_NUVEMPAGO_DASHBOARD_DATA_FROM_COMMONS = 'fs-nuvempago-dashboard-data-from-commons',
}

type IsFeatureFlagEnabledType = (
  featureFlagId: FeatureFlag,
) => Promise<boolean>;

const isFeatureFlagEnabled: IsFeatureFlagEnabledType = async (
  featureFlagId: FeatureFlag,
) => {
  const response = await axios.get(`/admin/flags/${featureFlagId}`);
  return response.data;
};

interface FeatureFlagServiceInterface {
  isFeatureFlagEnabled: IsFeatureFlagEnabledType;
}

const featureFlagService: FeatureFlagServiceInterface = {
  isFeatureFlagEnabled,
};

export default featureFlagService;
