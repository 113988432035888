import React from 'react';
import { useLocation } from 'react-router';
import { Box, Button } from '@nimbus-ds/components';
import {
  RocketIcon,
  StarIcon,
  TagIcon,
  TiendanubeIcon,
} from '@nimbus-ds/icons';
import { InitialScreen } from '@nimbus-ds/patterns';
import { goTo } from '@tiendanube/nexo/helpers';
import AppLayout from 'App/components/AppLayout';
import nexo from 'App/nexoClient';
import { useTranslationWithPrefix } from 'commons/utils';
import InstallationStatusAlert from './InstallationStatusAlert';

interface EmptyStateParams {
  isBtnDisabled: boolean;
  status: 'validationPending' | 'delayedValidation';
}

const EmptyStateMexico = () => {
  const { t } = useTranslationWithPrefix('landing.emptyState.onValidation');
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const isBtnDisabled = searchParams.get('isBtnDisabled') === 'true';
  const status = searchParams.get('status') as EmptyStateParams['status'];
  const requestDate = searchParams.get('requestDate');

  const handleClick = () => {
    goTo(nexo, '/settings/payments');
  };

  return (
    <AppLayout title="">
      <div data-testid="empty-mexico">
        <Box>
          {status && (
            <InstallationStatusAlert
              status={status}
              show={
                status === 'validationPending' || status === 'delayedValidation'
              }
              requestDate={requestDate}
            />
          )}
          <InitialScreen.Hero
            title={t('title')}
            subtitle={t('subtitle')}
            bullets={[
              <InitialScreen.Bullet
                key={11}
                icon={<TiendanubeIcon />}
                text={t('managePayments')}
              />,
              <InitialScreen.Bullet
                key={22}
                icon={<RocketIcon />}
                text={t('betterConversion')}
              />,
              <InitialScreen.Bullet
                key={33}
                icon={<TagIcon />}
                text={t('noInterestMonths')}
              />,
              <InitialScreen.Bullet
                key={44}
                icon={<StarIcon />}
                text={t('specializedSupport')}
              />,
            ]}
            actions={
              <Button
                appearance="primary"
                disabled={isBtnDisabled}
                onClick={handleClick}
              >
                {t('primaryBtn')}
              </Button>
            }
            image={
              <img
                src={'/assets/img/pago-nube-empty-state-mx.png'}
                alt="hero image"
                width="100%"
                height="auto"
              />
            }
          />
        </Box>
      </div>
    </AppLayout>
  );
};

export default EmptyStateMexico;
