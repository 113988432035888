/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Button, Icon, Text, Title } from '@nimbus-ds/components';
import { SlidersIcon } from '@nimbus-ds/icons';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import DashboardContext, {
  DashboardContextArgs,
} from '../../Dashboard.context';
import DashboardFiltersContext from '../filter/DashboardFiltersContext';
import FilterChips from '../filter/FilterChips';
import { filters } from '../list-v2/DashboardList';
import SearchBar, { filterObject } from '../search-v2/SearchBar';
import SideModalFilterV2 from './SideModalFilterV2';

type filtersArgs = {
  onFilterChange: (filtersObj: filters) => void;
  hasTransactionWithoutFilters: boolean;
};

function DashboardFiltersV2({
  onFilterChange,
  hasTransactionWithoutFilters,
}: filtersArgs) {
  const { status, toDate, fromDate, type, page, itemsPerPage } = useContext(
    DashboardFiltersContext,
  );
  const [open, setOpen] = useState<boolean>(false);
  const [objectQuery, setQuery] = useState<filterObject>();
  const { showSkeleton }: DashboardContextArgs = useContext(DashboardContext);
  const isMobile = useIsMobile();

  const { t } = useTranslationWithPrefix('dashboard.components.filters');

  useEffect(() => {
    navigateHeaderRemove(nexo);

    const filterObj: filters & filterObject = {
      status,
      date: {
        to: toDate,
        from: fromDate,
      },
      paymentMethod: type,
      page,
      itemsPerPage,
      ...objectQuery,
    };

    onFilterChange(filterObj);
  }, [
    status,
    toDate,
    fromDate,
    type,
    page,
    itemsPerPage,
    objectQuery,
    onFilterChange,
  ]);

  if (!hasTransactionWithoutFilters) return <></>;

  return (
    <Box display={'grid'} gridGap={'4'} data-testid="dashboard-filters-v2">
      {showSkeleton ? (
        <Box width={{ xs: '50%', md: '15%' }}>
          <Text.Skeleton width="80%" height="28px" />
        </Box>
      ) : (
        <Title as="h2">{t('title')}</Title>
      )}
      <Box display={'flex'} gap={'2-5'} justifyContent={'space-between'}>
        <SearchBar onChange={setQuery} />
        {showSkeleton ? (
          <Button.Skeleton data-testid="filter-button-skeleton" width="10%" />
        ) : (
          <Button
            onClick={() => {
              track('nuvempago_dashboard_filter_button_click');
              setOpen(!open);
            }}
            data-testid="filterButton"
          >
            <Icon source={<SlidersIcon />} />
            {isMobile ? <Box></Box> : t('filterButton')}
          </Button>
        )}
      </Box>
      <SideModalFilterV2 showModal={open} setShowModal={setOpen} />
      <FilterChips />
    </Box>
  );
}

export default DashboardFiltersV2;
