import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@nimbus-ds/components';
import { CheckCircleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import {
  getRelativeDateFromToday,
  useTranslationWithPrefix,
} from 'commons/utils';
import moment from 'moment';

interface DateRangeFilterInterface {
  fromDate: string | undefined;
  setFromDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  toDate: string | undefined;
  setToDate: React.Dispatch<React.SetStateAction<string | undefined>>;
}

enum DateError {
  LESS_THAN_MIN = 'lessThanMinimum',
  TO_DATE_BEFORE_FROM_DATE = 'toDateBeforeFromDate',
}

type ValidationResult = {
  isValid: boolean;
  error?: DateError;
};

const MIN_DATE_SUPPORTED = '2024-01-01';

function DateRangeFilterV2({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}: DateRangeFilterInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('home');
  const [fromDateError, setFromDateError] = useState<DateError | undefined>();
  const [toDateError, setToDateError] = useState<DateError | undefined>();
  const today = getRelativeDateFromToday(0);

  // Set default dates
  useEffect(() => {
    if (!fromDate && !toDate) {
      const oneYearAgo = getRelativeDateFromToday(-365);
      setFromDate(oneYearAgo);
      setToDate(today);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toDateError === DateError.TO_DATE_BEFORE_FROM_DATE)
      setToDateError(undefined);

    if (toDate && fromDate && moment(toDate).isBefore(fromDate))
      setToDateError(DateError.TO_DATE_BEFORE_FROM_DATE);
  }, [fromDate, toDate, toDateError, setToDateError]);

  const isValidDateFormat = (date: string) => {
    return moment(date, 'YYYY-MM-DD', true).isValid();
  };

  const validateDate = useCallback((newDate: string): ValidationResult => {
    if (!isValidDateFormat(newDate))
      return { isValid: false, error: DateError.LESS_THAN_MIN };

    const date = moment(newDate);

    if (date.isBefore(MIN_DATE_SUPPORTED))
      return { isValid: false, error: DateError.LESS_THAN_MIN };

    return { isValid: true };
  }, []);

  const handleFromDateChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const newFromDate = target.value;
    const { isValid, error } = validateDate(newFromDate);

    if (!isValid) {
      setFromDateError(error);
      setFromDate(undefined);
      return;
    }

    setFromDateError(undefined);
    setFromDate(newFromDate);
  };

  const handleToDateChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const newToDate = target.value;
    const { isValid, error } = validateDate(newToDate);

    if (!isValid) {
      setToDateError(error);
      setToDate(undefined);
      return;
    }

    setToDateError(undefined);
    setToDate(newToDate);
  };

  return (
    <>
      <Box
        display="flex"
        flex-direction="column"
        align-items="flex-start"
        gap="2"
        flex="1 0 0"
      >
        <FormField.Input
          label={t('transactionsSearch.fromDate', { date: '' })}
          name="fromDate"
          type="date"
          value={fromDate}
          min={MIN_DATE_SUPPORTED}
          max={today}
          onChange={handleFromDateChange}
          aria-invalid={!!fromDateError}
          showHelpText={!!fromDateError}
          appearance={!!fromDateError ? 'danger' : 'none'}
          helpText={t(`dateRangeOptions.validationErrors.${fromDateError}`)}
          helpIcon={CheckCircleIcon}
          data-testid="fromDate"
        />
        <FormField.Input
          label={t('transactionsSearch.toDate', { date: '' })}
          name="toDate"
          type="date"
          min={MIN_DATE_SUPPORTED}
          max={today}
          onChange={handleToDateChange}
          value={toDate}
          aria-invalid={!!toDateError}
          showHelpText={!!toDateError}
          appearance={!!toDateError ? 'danger' : 'none'}
          helpText={t(`dateRangeOptions.validationErrors.${toDateError}`)}
          helpIcon={CheckCircleIcon}
          data-testid="toDate"
        />
      </Box>
    </>
  );
}

export default DateRangeFilterV2;
