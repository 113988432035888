import { OrderType } from 'commons/types/OrderType.enum';
import FilterStatus from 'domains/Brazil/commons/types/TransactionStatusFilterEnum';
import { filterObject } from 'domains/Brazil/Dashboard/components/search/SearchBar';
import moment from 'moment';
import { FailureReason, Origin } from './dashboard.service';

export type filters = {
  date?: {
    from: string | undefined;
    to: string | undefined;
  };
  paymentMethod?: string;
  status?: FilterStatus;
  withOrderNumber?: boolean;
  orderNumber?: string;
  orderType?: string;
  document?: string;
  paymentAmount?: string;
  customer?: string;
  page?: number;
  itemsPerPage?: number;
};

export enum ChargesDashboardStatus {
  Pending = 'pending',
  Authorized = 'authorized',
  FraudAnalysisApproved = 'fraud_analysis_approved',
  Paid = 'paid',
  Refunded = 'refunded',
  PartiallyRefunded = 'partially_refunded',
  Failed = 'failed',
  Unknown = 'unknown',
  Chargebacked = 'chargebacked',
  ChargebackDispute = 'chargeback_dispute',
  Expired = 'expired',
}

enum DateRange {
  All = 'all',
  Yesterday = 'yesterday',
  SevenDays = 'seven_days',
  ThirtyDays = 'thirty_days',
  Custom = 'custom',
}

export type ApiFilter = {
  fromDate: string;
  toDate: string;
  search: string;
  status: string;
  hasChargeback: string;
  failureReason: string;
  origin: string;
  range: DateRange;
  amount: number;
  orderNumber: string;
  page: number;
  items: number;
};

export const apiStatusMap = new Map<FilterStatus, string>([
  [FilterStatus.REJECTED_BY_EMISSOR, ChargesDashboardStatus.Failed],
  [FilterStatus.REJECTED_BY_SECURITY, ChargesDashboardStatus.Failed],
  [FilterStatus.ALL_REJECTED, ChargesDashboardStatus.Failed],
  [FilterStatus.PAID, ChargesDashboardStatus.Paid],
  [FilterStatus.REFUND, ChargesDashboardStatus.Refunded],
  [FilterStatus.PARTIAL_REFUND, ChargesDashboardStatus.PartiallyRefunded],
  [FilterStatus.CHARGEBACKED, ChargesDashboardStatus.Chargebacked],
  [FilterStatus.EXPIRED, ChargesDashboardStatus.Expired],
  [FilterStatus.WAITING, ChargesDashboardStatus.Pending],
  [
    FilterStatus.INPROCESS,
    `${ChargesDashboardStatus.Authorized},${ChargesDashboardStatus.FraudAnalysisApproved}`,
  ],
]);

const startOfDay = (date: string) => moment(date).startOf('day').toISOString();
const endOfDay = (date: string) => moment(date).endOf('day').toISOString();

const formatAmount = (cents: string): number => {
  const floatValue = parseFloat(cents) / 100;
  return +floatValue.toFixed(2);
};

export function filterBuilder(
  filters: filters & filterObject = {},
): Partial<ApiFilter> {
  const apiFilter: Partial<ApiFilter> = {};

  if (filters.date?.from) apiFilter.fromDate = startOfDay(filters.date?.from);
  if (filters.date?.to) apiFilter.toDate = endOfDay(filters.date?.to);
  if (filters.date?.from || filters.date?.to) {
    apiFilter.range = DateRange.Custom;
  }

  if (filters.status && filters.status !== FilterStatus.ALL) {
    apiFilter.status = apiStatusMap.get(filters.status);

    if (filters.status === FilterStatus.REJECTED_BY_EMISSOR) {
      apiFilter.failureReason = FailureReason.rejectedByEmissor;
    } else if (filters.status === FilterStatus.REJECTED_BY_SECURITY) {
      apiFilter.failureReason = FailureReason.rejectedBySecurity;
    }
  }

  if (filters.paymentAmount) {
    apiFilter.amount = formatAmount(filters.paymentAmount);
  }

  if (filters.customer) apiFilter.search = filters.customer;

  if (filters.orderNumber) apiFilter.orderNumber = filters.orderNumber;

  if (filters.paymentMethod && filters.paymentMethod !== 'all') {
    apiFilter.origin =
      filters.paymentMethod.toLowerCase() === OrderType.Checkout.toLowerCase()
        ? Origin.NuvemShop
        : Origin.PaymentLink;
  } else if (filters.orderType) {
    apiFilter.origin =
      filters.orderType === 'payment_link'
        ? Origin.PaymentLink
        : Origin.NuvemShop;
  }

  if (filters.page) apiFilter.page = filters.page;

  if (filters.itemsPerPage) apiFilter.items = filters.itemsPerPage;

  return apiFilter;
}
