import React, { useCallback, useContext } from 'react';
import { useEffect, useState } from 'react';
import { Box, ToastProvider, useToast } from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import { FeatureFlag, featureFlagService } from 'commons/services';
import paymentLinkService from 'commons/services/paymentLink/paymentLinkService';
import { PaymentLinkDetailsType } from 'commons/types/PaymentLinkDetailsType';
import { useTranslationWithPrefix } from 'commons/utils';
import MobileTopNavBar from 'domains/Brazil/commons/components/MobileTopNavBar';
import NuvemPagoCta from 'domains/Brazil/commons/components/NuvemPagoCta';
import { TypeTagV2 } from 'domains/Brazil/commons/components/StatusTagV2';
import {
  NotificationBRContext,
  NotificationType,
} from 'domains/Brazil/Notifications/Notification.context';
import { AlertRefund } from 'domains/Brazil/Refund/components/AlertRefund/AlertRefund';
import AddressCard from './components/AddressCard';
import CustomerCard from './components/CustomerCard';
import DescriptionCard from './components/DescriptionCard';
import HeaderBox from './components/HeaderBox';
import PaymentCard from './components/PaymentCard';
import ShareLinkCard from './components/ShareLinkCard';
import SummaryCard from './components/SummaryCard';

interface PaymentLinkDetailParams {
  identifier: string;
  identifierType: IdType;
}

export enum IdType {
  ID = 'id',
  UUID = 'uuid',
}

interface CustomAlertProps {
  title: string;
  message: string;
  linkTitle?: string;
  linkUrl?: string;
  appearance:
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'neutral'
    | undefined;
  testid: string;
}

function PaymentLinkDetail({
  identifier,
  identifierType,
}: PaymentLinkDetailParams): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentLink');
  const { t: navT } = useTranslationWithPrefix('navigation');
  const [details, setDetails] = useState<PaymentLinkDetailsType | undefined>();
  const isMobile = useIsMobile();
  const { addToast } = useToast();
  const [isShowAlert, setIsShowAlert] = useState(false);
  const notificationContext = useContext(NotificationBRContext);
  const [isRefundPartialEnabled, setIsRefundPartialEnabled] =
    useState<boolean>(false);

  // eslint-disable-next-line max-len
  const [customAlertProps, setCustomAlertProps] = useState<CustomAlertProps>({
    title: '',
    message: '',
    linkTitle: '',
    appearance: undefined,
    testid: '',
  });

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const details =
          identifierType === IdType.ID
            ? await paymentLinkService.getDetailsById(identifier)
            : await paymentLinkService.getDetailsByUuid(identifier);

        details.payment.referenceUUID = details.referenceUUID;
        details.payment.orderStatus = details.status;
        setDetails(details);
        const refundSuccessNotifications =
          notificationContext.findNotificationByType(
            NotificationType.RefundSuccess,
          );
        if (refundSuccessNotifications.length > 0) {
          handleConsumeRefundSuccessNotification(
            refundSuccessNotifications[0].id,
          );
        }
        const refundPartialIsEnabled =
          await featureFlagService.isFeatureFlagEnabled(
            FeatureFlag.FS_NUVEMPAGO_REFUND_PARTIAL_ROLLOUT,
          );
        setIsRefundPartialEnabled(refundPartialIsEnabled);
      } catch (error) {
        addToast({
          type: 'danger',
          text: t('details.error.getDetailsFailure'),
          duration: 4000,
          id: 'payment-link-failure-toast',
        });
      }
    };

    const handleConsumeRefundSuccessNotification = (notificationId: string) => {
      notificationContext.removeNotification(notificationId);
      setCustomAlertProps({
        title: t('refund.success.title'),
        message: t('refund.success.message'),
        appearance: 'success',
        testid: 'PaymentLink-Refund-Success-Alert',
      });
      setIsShowAlert(true);
    };

    fetchDetails();
    navigateHeader(nexo, {
      goTo: ROUTES.DASHBOARD,
      text: navT('back'),
    });
  }, [identifier, identifierType, addToast, t, notificationContext, navT]);

  const renderSharedLink = () => {
    if (
      details?.status.toUpperCase() == TypeTagV2.RejectedBySecurity ||
      details?.status.toUpperCase() == TypeTagV2.Failed
    ) {
      return (
        <Box marginBottom={'4'}>
          <ShareLinkCard link={details?.paymentUrl} />
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const handleAlertCloseClick = useCallback(() => {
    setIsShowAlert(false);
  }, []);

  const renderDeskTopGrid = () => (
    <Box
      width={'100%'}
      padding={'4'}
      backgroundColor="neutral-surface"
      data-testid={`PaymentLinkDetail${
        identifierType === IdType.ID ? 'V2' : 'V1'
      }`}
    >
      <Box
        display="grid"
        gridTemplateAreas='"margin-l hd margin-r"
                      "margin-l cards margin-r"
                      "margin-l ft margin-r"'
        gridTemplateColumns={'[margin-l] [cards] 3fr [margin-r]'}
        alignItems={'flex-start'}
        justifyContent={'center'}
        flexWrap={'wrap'}
        flexDirection={'column'}
      >
        <Box display="grid" gridArea={'hd'} marginBottom={'2'}>
          <HeaderBox
            details={details}
            isRefundPartialEnabled={isRefundPartialEnabled}
          />
          <AlertRefund
            isShowAlert={isShowAlert}
            title={customAlertProps.title}
            message={customAlertProps.message}
            linkTitle={customAlertProps.linkTitle}
            linkUrl={customAlertProps?.linkUrl}
            appearance={customAlertProps.appearance}
            handleAlertCloseClick={handleAlertCloseClick}
            testid={customAlertProps.testid}
          />
        </Box>
        <Box
          display={'grid'}
          gridArea={'cards'}
          gap={'8'}
          gridTemplateAreas='"lcard rcard"'
          gridTemplateColumns={'[lcard] 2fr [rcard] 1fr'}
        >
          <Box display="grid" gridArea={'lcard'} gap={'5'}>
            <SummaryCard summary={details?.payment} />
            <DescriptionCard description={details?.description} />
            <PaymentCard summary={details?.payment} />
          </Box>
          <Box display={'grid'} gridArea={'rcard'}>
            <Box display="flex" flexDirection={'column'} gap={'5'}>
              <CustomerCard customer={details?.customer} />
              <AddressCard address={details?.shippingAddress} />
              {renderSharedLink()}
            </Box>
          </Box>
        </Box>
        <Box display="grid" gridArea={'ft'} marginTop={'6'}>
          <NuvemPagoCta
            href={
              'https://atendimento.nuvemshop.com.br/pt_BR/configuracao-nuvem-pago/como-criar-um-link-de-pagamento-do-nuvem-pago'
            }
            title={t('details.cta')}
          />
        </Box>
      </Box>
    </Box>
  );

  const renderMobileGrid = () => (
    <Box padding={'4'}>
      <MobileTopNavBar
        backLinkPath={ROUTES.DASHBOARD}
        padding="none"
        marginBottom={'4'}
      />
      <Box display="grid" gap="4">
        <HeaderBox
          details={details}
          isRefundPartialEnabled={isRefundPartialEnabled}
        />
        <AlertRefund
          isShowAlert={isShowAlert}
          title={customAlertProps.title}
          message={customAlertProps.message}
          linkTitle={customAlertProps.linkTitle}
          linkUrl={customAlertProps?.linkUrl}
          appearance={customAlertProps.appearance}
          handleAlertCloseClick={handleAlertCloseClick}
          testid={customAlertProps.testid}
        />
        <SummaryCard summary={details?.payment} />
        <DescriptionCard description={details?.description} />
        <PaymentCard summary={details?.payment} />
        <AddressCard address={details?.shippingAddress} />
        {renderSharedLink()}
        <NuvemPagoCta
          href={
            'https://atendimento.nuvemshop.com.br/pt_BR/configuracao-nuvem-pago/como-criar-um-link-de-pagamento-do-nuvem-pago'
          }
          title={t('details.cta')}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <ToastProvider>
        {isMobile ? renderMobileGrid() : renderDeskTopGrid()}
      </ToastProvider>
    </>
  );
}
export default PaymentLinkDetail;
