import { ReportDateRangeType } from 'commons/types';
import moment from 'moment';
import { IStoreInfo } from 'redux/slices/types';
import { MIN_REPORT_DAY } from './constants';

export const dateHasPassed = (dateString: string | Date): boolean => {
  const today = new Date();
  const date = new Date(dateString);
  return date < today;
};

export const daysPassedSince = (dateString: string): number => {
  const today = new Date();
  const date = new Date(dateString);
  const difference = today.getTime() - date.getTime();
  const daysPassed = Math.floor(difference / (1000 * 60 * 60 * 24));
  return daysPassed;
};

export const daysUntil = (dateString: Date): number => {
  const today = new Date();
  const date = dateString;
  const difference = date.getTime() - today.getTime();
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  return days;
};

export const earliestDate = (dates: Date[]): Date => {
  return new Date(Math.min(...dates.map((date) => date.getTime())));
};

export const getRelativeDateFromToday = (days = 0): string => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.toISOString().split('T')[0];
};

export const todayDate = (): string => {
  return getRelativeDateFromToday(0);
};

export const formatLongDateWithoutYear = (
  storeInfo: IStoreInfo,
  date: string,
): string => {
  // Date() converts from UTC to users timezone but 'date' comes without timezone
  // so to fix "off by 1 day" bug we add the timezone offset
  const dateFormatted = new Date(date);
  dateFormatted.setMinutes(
    dateFormatted.getMinutes() + dateFormatted.getTimezoneOffset(),
  );

  const locale = `${storeInfo.language}-${storeInfo.country}`;
  return dateFormatted.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'long',
  });
};

export const formatLongDateTime = (
  storeInfo: IStoreInfo,
  date: string,
): string => {
  const locale = `${storeInfo.language}-${storeInfo.country}`;
  return new Date(date)
    .toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(',', ' -');
};

export const formatDate = (storeInfo: IStoreInfo, date: string): string => {
  const dateFormated = moment(date).locale(storeInfo.language).format('D MMM');
  return dateFormated.replace('.', '');
};

export const formatLongDate = (storeInfo: IStoreInfo, date: string): string => {
  const locale = `${storeInfo.language}-${storeInfo.country}`;
  return new Date(date)
    .toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
    .replace('-', ' ');
};

export const formatNumericDate = (
  storeInfo: IStoreInfo,
  date: string,
  sep: string = '/',
): string => {
  const locale = `${storeInfo.language}-${storeInfo.country}`;
  return new Date(date)
    .toLocaleDateString(locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replaceAll('/', sep);
};

export const formatNumericDayMonth = (
  date: string | Date,
  sep: string = '/',
): string => {
  const newDate = new Date(date);
  const day = String(newDate.getDate()).padStart(2, '0');
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  return `${day}${sep}${month}`;
};

export const formatTime = (
  date: string,
  use24HourFormat: boolean = false,
): string => {
  return new Date(date).toLocaleTimeString('default', {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: use24HourFormat ? 'h23' : 'h12',
  });
};

export const formatPaymentDate = (
  date: string | Date,
  padding: number,
  locale: string,
) => {
  const newDate = moment(date).add(padding, 'days').locale(locale);

  return newDate.format('D [de] MMMM [a las] HH:mm');
};

export const formatSpanishLongDate = (date: Date): string => {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = new Intl.DateTimeFormat('es-AR', { month: 'long' }).format(
    newDate,
  );
  const hour = newDate.getHours();

  return `${day} de ${month} a las ${hour}h`;
};

export const reportRangeToDates = (
  dateRange: ReportDateRangeType,
  fromDate?: string,
  toDate?: string,
): {
  fromDate: string;
  toDate: string;
} => {
  const minimumDate = moment(MIN_REPORT_DAY);
  let newFromDate = minimumDate;
  let newToDate = moment();

  switch (dateRange) {
    case 'all':
      break;
    case 'last-24-hours':
      newFromDate = moment().subtract(1, 'days');
      break;
    case 'last-7-days':
      newFromDate = moment().subtract(7, 'days');
      break;
    case 'last-month':
      newFromDate = moment().subtract(1, 'months').startOf('month');
      newToDate = moment().subtract(1, 'months').endOf('month');
      break;
    case 'custom':
      newFromDate = moment(fromDate).startOf('day');
      newToDate = moment(toDate).endOf('day');
      break;
  }

  if (newFromDate.isBefore(minimumDate)) newFromDate = minimumDate;
  if (newToDate.isBefore(minimumDate)) newToDate = minimumDate;

  return {
    fromDate: newFromDate.format(),
    toDate: newToDate.format(),
  };
};
