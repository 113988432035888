import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import {
  Alert,
  Box,
  Button,
  Card,
  Icon,
  Link,
  Spinner,
  Text,
  Title,
} from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import AppLayout from 'App/components/AppLayout';
import {
  CustomEvents,
  encryptData,
  sendCustomEvent,
} from 'App/fingerprintClient';
import { ROUTES } from 'App/Routes/constants';
import {
  BankAccountInfo,
  ErrorMessage,
  ProofOfLifePendingModal,
} from 'commons/components';
import {
  useBankAccount,
  useFeatureFlag,
  useIdentityValidation,
  useLastBalanceBeforeTransfer,
  useLoading,
  useStoreInfo,
} from 'commons/hooks';
import { useCountry } from 'commons/hooks/useCountry';
import {
  FeatureFlag,
  merchantService,
  notificationService,
} from 'commons/services';
import { MoneyObjectInterface, NotificationStyleType } from 'commons/types';
import { formatCurrency, useTranslationWithPrefix } from 'commons/utils';
import { getTransferErrorParsed } from './getTransferErrorParsed';
import MoneyCard from './MoneyCard';
import TransferSkeleton from './TransferSkeleton';

type AlertAppearence =
  | 'primary'
  | 'danger'
  | 'success'
  | 'warning'
  | 'neutral'
  | undefined;

interface stateType {
  showRegistrationSuccessModal: boolean;
}

function Transfer(): JSX.Element {
  const { t } = useTranslationWithPrefix('transfer');
  const { storeInfo } = useStoreInfo();
  const { isMexico } = useCountry();
  // TODO: remove when finishing proof of life remediation
  const identityValidation = useIdentityValidation({
    refetchOnWindowFocus: !isMexico,
  });
  const [transferDisabled, setTransferDisabled] = useState(false);
  const location = useLocation<stateType>();
  const { isLoading, setIsLoading } = useLoading(true);
  const [hasError, setHasError] = useState(false);
  const bankAccount = useBankAccount();
  const history = useHistory();
  const [alertState, setAlertState] = useState({
    appearance: 'primary' as AlertAppearence,
    children: '',
    show: false,
    title: '',
  });
  const [availableToWithdraw, setAvailableToWithdraw] =
    useState<MoneyObjectInterface>({
      value: 0,
      currency: '',
    });
  const [showProofOfLifePendingModal, setShowProofOfLifePendingModal] =
    useState(false);
  const { setLastBalance } = useLastBalanceBeforeTransfer();
  const { enabled: transferFlagDisabled } = useFeatureFlag(
    FeatureFlag.FS_PAGONUBE_TRANSFER_DISABLED,
  );

  useEffect(() => {
    if (location.state?.showRegistrationSuccessModal) {
      setAlertState({
        appearance: 'success',
        children: t('registerSuccess.description'),
        show: true,
        title: t('registerSuccess.title'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.showRegistrationSuccessModal]);

  useEffect(() => {
    async function fetchBalance() {
      setIsLoading(true);
      try {
        const balance = await merchantService.getBalance();
        setAvailableToWithdraw(balance.availableToWithdraw);
      } catch (error) {
        setHasError(true);
      }
      setIsLoading(false);
    }

    fetchBalance();
  }, [setIsLoading]);

  if (bankAccount.isLoading || identityValidation.isLoading || isLoading)
    return <TransferSkeleton />;

  if (
    bankAccount.isError ||
    (!isMexico && identityValidation.isError) ||
    hasError
  ) {
    return (
      <AppLayout
        backLinkText={t('nuvemshopPanel')}
        title={t('title')}
        subtitle={t('subtitle')}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          justifyContent="flex-end"
          gap="4"
        >
          <ErrorMessage />
        </Box>
      </AppLayout>
    );
  }

  function closeAlert() {
    setAlertState({
      appearance: 'primary',
      children: '',
      show: false,
      title: '',
    });
  }

  const redirectToBankAccountRegistration = () => {
    history.push(ROUTES.BANK_ACCOUNT_CONFIG);
  };

  const handleEditClick = () => {
    track('pago-nube-editar-cbu');
    redirectToBankAccountRegistration();
  };

  const notifyAndRedirectToHome = async (
    title: string,
    description: string,
    style: NotificationStyleType,
    showLoadingDelay = false,
  ) => {
    await notificationService.sendNotification({
      title: t(`alert.${title}`),
      message: t(`alert.${description}`),
      style,
    });
    history.push(ROUTES.HOME, { showLoadingDelay });
  };

  function afterTransfer() {
    track('pago-nube-retirar-saldo', { amount: availableToWithdraw });
    setLastBalance(availableToWithdraw);
    notifyAndRedirectToHome(
      'successTitle',
      'successDescription',
      'success',
      true,
    );
  }

  function transferMoney() {
    if (identityValidation.data?.isProofOfLifePending) {
      setShowProofOfLifePendingModal(true);
      return;
    }

    setTransferDisabled(true);

    const event = {
      tag: CustomEvents.TRANSFER,
      properties: {
        currency: availableToWithdraw.currency,
        amount: availableToWithdraw.value,
        bankAccountName: encryptData(bankAccount.data?.bankName?.toString()),
        bankAccountBeneficiary: encryptData(
          bankAccount.data?.holderName?.toString(),
        ),
        bankAccountFiscalNumber: encryptData(
          bankAccount.data?.fiscalDocument?.toString(),
        ),
        bankAccountNumber: encryptData(
          bankAccount.data?.accountNumber?.toString(),
        ),
      },
    };

    sendCustomEvent(event);

    merchantService
      .transfer()
      .then(() => afterTransfer())
      .catch((err) => {
        const parsedError = getTransferErrorParsed(
          err.response?.data?.failureCode,
        );
        notifyAndRedirectToHome(
          parsedError.title,
          parsedError.description,
          'error',
        );
      });
  }

  return (
    <AppLayout
      backLinkText={t('nuvemshopPanel')}
      title={t('title')}
      subtitle={t('subtitle')}
      footer={
        <Button
          onClick={transferMoney}
          appearance="primary"
          disabled={
            transferDisabled ||
            (!isMexico && identityValidation?.data?.status === 'denied') ||
            transferFlagDisabled
          }
        >
          {transferDisabled && <Spinner size="small" />}
          {t('transfer')}
        </Button>
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="flex-end"
        gap="4"
      >
        <ProofOfLifePendingModal
          onDismiss={() => setShowProofOfLifePendingModal(false)}
          open={showProofOfLifePendingModal}
          proofOfLifeUrl={identityValidation?.data?.proofOfLifeUrl}
        />
        <Alert {...alertState} onRemove={closeAlert} />
        <Alert title={t('infoAlert.title')} show appearance="neutral">
          {t('infoAlert.subtitle')}
        </Alert>
        <MoneyCard
          title={t('transferValue')}
          subtitle={formatCurrency(availableToWithdraw, storeInfo)}
        />
        <Card>
          <Card.Header padding="none">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Title as="h3">{t('destinyAccount')}</Title>

              <Link
                textDecoration="none"
                appearance="primary"
                onClick={handleEditClick}
              >
                <Icon color="currentColor" source={<EditIcon />} />
                {t('edit')}
              </Link>
            </Box>
          </Card.Header>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap="4"
          >
            <Text>
              {t('coOwnership')}
              <Link as="a" href={`mailto: ${t('email')}`}>
                {t('email')}
              </Link>
            </Text>
            <BankAccountInfo
              bankAccountInfo={{
                bank: bankAccount.data?.bankName || '',
                fiscalNumber: bankAccount.data?.fiscalDocument || '',
                accountNumber: bankAccount.data?.accountNumber || '',
                beneficiary: bankAccount.data?.holderName || '',
              }}
            />
          </Box>
        </Card>
      </Box>
    </AppLayout>
  );
}

Transfer.Skeleton = TransferSkeleton;
export default Transfer;
