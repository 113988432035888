/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Alert, Box, Card, Radio, Text, Title } from '@nimbus-ds/components';
import { StoreIcon, UserIcon } from '@nimbus-ds/icons';
import { useToast } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import { bankingService } from 'domains/Brazil/commons/services/bankingService';
import BasicLayout from '../../components/BasicLayout';
import FooterButtons from '../../components/FooterButtons';
import IconText from '../../components/IconText';
import { IdentificationDocumentInstructionsList } from '../../components/InstructionsList/IdentificationDocumentInstructionsList';
import ThumbnailFileUploader from '../../components/ThumbnailFileUploader';
import {
  BankingIdentityValidationPageProps,
  InterfaceSolicitation,
  RecipientInfo,
} from '../../utils/interfaces';
import { trackUpload } from '../../utils/tracking';
import { DocumentSide } from '../../utils/types/DocumentSide.enum';
import { DocumentType } from '../../utils/types/DocumentType.enum';

type FlexColumnProps = React.ComponentProps<typeof Box> & {
  children: React.ReactNode;
};

const FlexColumn = ({ children, ...props }: FlexColumnProps) => (
  <Box display="flex" flexDirection="column" {...props}>
    {children}
  </Box>
);

type DocumentOptionListProps = {
  solicitations: InterfaceSolicitation[];
  selectedDocument: InterfaceSolicitation | undefined;
  handleDocumentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
};

const DocumentOptionList = ({
  title,
  solicitations,
  selectedDocument,
  handleDocumentChange,
}: DocumentOptionListProps) => {
  return (
    <FlexColumn gap="4">
      <Title as="h4">{title}</Title>

      {solicitations.map((documentOption) => (
        <Radio
          name="documentType"
          onChange={(event) => handleDocumentChange(event)}
          key={documentOption.type}
          checked={selectedDocument?.type === documentOption.type}
          label={documentOption.type}
          value={documentOption.type}
          id={documentOption.type}
        />
      ))}
    </FlexColumn>
  );
};

export type SelectDocumentStepPageProps = BankingIdentityValidationPageProps & {
  recipientInfo: RecipientInfo;
  solicitations?: InterfaceSolicitation[];
  children?: React.ReactNode;
  documentTypes?: DocumentType[];
};

const MAX_FILE_10_MB = 10 * 1024 * 1024; // 10MB em bytes

type DocumentFile = {
  side: DocumentSide;
  file: File;
};

export function SelectDocumentStepPage({
  previousStep,
  nextStep,
  recipientInfo,
  solicitations = [],
  isDocumentResend,
  ...props
}: SelectDocumentStepPageProps): JSX.Element | null {
  const isIndividual = recipientInfo.recipientType === 'INDIVIDUAL';

  const [selectedDocument, setSelectedDocument] = useState<
    InterfaceSolicitation | undefined
  >();

  const [selectedDocumentFilesMap, setSelectedDocumentFilesMap] = useState<
    Map<DocumentSide, DocumentFile>
  >(new Map());

  const [allDocumentsLoaded, setAllDocumentsLoaded] = useState(false);

  const [fileSizeError, setFileSizeError] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [resetKey, setResetKey] = useState(false);

  const { showToastSuccess, showToastError } = useToast();

  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.selectDocumentsPage',
  );

  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDocument = solicitations.find(
      (solicitation) => solicitation.type === event.target.value,
    );

    setSelectedDocumentFilesMap(new Map());
    setSelectedDocument(selectedDocument);
    setResetKey((prevKey) => !prevKey);
  };

  const handleError = (hasError: boolean, text: string) => {
    showToastError(text);
    setFileSizeError(hasError);
  };

  const onSelectFileHandler = React.useCallback(
    (documentSide: DocumentSide, file: File) => {
      if (!selectedDocument) return;

      setSelectedDocumentFilesMap((prevFiles) => {
        const newFiles = new Map(prevFiles);
        newFiles.set(documentSide, { side: documentSide, file });

        const allLoaded = Array.from(selectedDocument.sides).every((side) =>
          newFiles.has(side),
        );
        setAllDocumentsLoaded(allLoaded);

        return newFiles;
      });

      showToastSuccess(t('toast.success'));
      setFileSizeError(false);
    },
    [selectedDocument, showToastSuccess, t],
  );

  const onDeleteHandler = (documentSide: DocumentSide) => {
    if (!selectedDocument) return;

    setSelectedDocumentFilesMap((prevFiles) => {
      const newFiles = new Map(prevFiles);
      newFiles.delete(documentSide);

      const allLoaded = Array.from(selectedDocument.sides).every((side) =>
        newFiles.has(side),
      );
      setAllDocumentsLoaded(allLoaded);

      return newFiles;
    });
  };

  async function uploadFile() {
    if (!selectedDocument || !allDocumentsLoaded) {
      setError(true);
      return;
    }

    setLoading(true);

    try {
      await bankingService.addAccountDocumentV2(recipientInfo.recipientType, {
        type: selectedDocument.type,
        sides: selectedDocumentFilesMap,
      });

      trackUpload(
        recipientInfo.recipientType,
        selectedDocument.type,
        isDocumentResend,
      );

      nextStep();
    } catch (error) {
      showToastError(t('toast.error'));
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  const handlePrimaryButton = async () => {
    await uploadFile();
  };

  const refreshOnError = () => {
    setError(false);
  };

  useEffect(() => {
    if (!solicitations) return;

    if (solicitations.length === 1) {
      const [uniqueSolicitation] = solicitations;
      setSelectedDocument(uniqueSolicitation);
    }
  }, [solicitations]);

  useEffect(() => {
    const hasSelectedDocument = !!selectedDocument;

    if (!hasSelectedDocument) {
      setAllDocumentsLoaded(false);
      return;
    }

    const check = Array.from(selectedDocument?.sides).every((side) =>
      selectedDocumentFilesMap.has(side),
    );

    setAllDocumentsLoaded(check);
  }, [selectedDocumentFilesMap, selectedDocument, setSelectedDocumentFilesMap]);

  if (!recipientInfo) return null;
  if (!solicitations.length) return null;

  const documentSides =
    (selectedDocument?.sides && Array.from(selectedDocument.sides)) || [];

  const hasMultipleDocumentSide = documentSides.length > 1;

  return (
    <BasicLayout
      title={isDocumentResend ? t('resendDocument') : t('sendDocument')}
      step={t('step')}
      error={error}
      refreshHandler={refreshOnError}
    >
      <Box
        data-testid="select-document-step-page"
        display="flex"
        flexDirection="column"
        maxWidth="800px"
        margin="auto"
      >
        {recipientInfo.document && (
          <>
            <FlexColumn gap="4" padding="none">
              <FlexColumn gap="4">
                <Text
                  fontSize="highlight"
                  fontWeight="medium"
                  color="neutral-textLow"
                >
                  {t('documentRelatedWithStore', {
                    document: isIndividual ? ' CPF ' : ' CNPJ ',
                  })}
                </Text>
                <IconText
                  icon={isIndividual ? <UserIcon /> : <StoreIcon />}
                  text={
                    isIndividual
                      ? `CPF ${recipientInfo.document}`
                      : `CNPJ ${recipientInfo.document}`
                  }
                />
              </FlexColumn>

              {solicitations.length > 1 && (
                <Box display="flex" gap="4">
                  <Card>
                    <DocumentOptionList
                      title={t('documentType.title')}
                      solicitations={solicitations}
                      selectedDocument={selectedDocument}
                      handleDocumentChange={handleDocumentChange}
                    />
                  </Card>
                </Box>
              )}

              {selectedDocument && (
                <Card>
                  {isDocumentResend && !hasMultipleDocumentSide ? (
                    <Title as="h4">
                      {t('instructionsContainer.resendDocument', {
                        document: selectedDocument.type,
                        side: t(`document.side.${documentSides[0]}`),
                      })}
                    </Title>
                  ) : (
                    <Title as="h4">
                      {t('instructionsContainer.addDocument', {
                        document: selectedDocument.type,
                      })}
                    </Title>
                  )}

                  {props.children}
                  <IdentificationDocumentInstructionsList />

                  {fileSizeError && (
                    <Box flex="1">
                      <Alert appearance="danger" title={t('alert.errorTitle')}>
                        <Text>{t('textFileSizeError')}</Text>
                      </Alert>
                    </Box>
                  )}

                  <Box display="flex" gap="4">
                    {documentSides.map((sideDocument, index) => (
                      <Box flex="1" key={index} gap="2">
                        <ThumbnailFileUploader
                          accept="image/jpeg,image/png"
                          ariaLabel={sideDocument}
                          fileName={sideDocument}
                          helperText={t(`document.side.${sideDocument}`)}
                          maxFileSize={MAX_FILE_10_MB}
                          onSelectFile={(event) =>
                            onSelectFileHandler(sideDocument, event)
                          }
                          onDelete={() => onDeleteHandler(sideDocument)}
                          onError={() => handleError(true, t('toast.error'))}
                          aspectRatio="16/9"
                          resetKey={resetKey}
                        />
                      </Box>
                    ))}
                  </Box>
                </Card>
              )}
            </FlexColumn>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              gap="2"
              marginTop="4"
            >
              <FooterButtons
                primaryButtonHandler={handlePrimaryButton}
                secondaryButtonHandler={previousStep}
                primaryButtonLabel={t('buttons.primary')}
                secondaryButtonLabel={t('buttons.secondary')}
                isSubmitting={isLoading}
                isDisabled={
                  isLoading || !selectedDocument || !allDocumentsLoaded
                }
              />
            </Box>
          </>
        )}
      </Box>
    </BasicLayout>
  );
}

export default SelectDocumentStepPage;
