import React, { useEffect, useState } from 'react';
import { Box, Title } from '@nimbus-ds/components';
import { UploadIcon, UserIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import BasicLayout from '../../components/BasicLayout';
import FooterButtons from '../../components/FooterButtons';
import DocumentIcon from '../../components/icons/DocumentIcon';
import IconText from '../../components/IconText';
import {
  BankingIdentityValidationPageProps,
  RecipientInfo,
} from '../../utils/interfaces';
import { trackNavigation } from '../../utils/tracking';
import IntroductionStepPageSkeleton from './IntroductionStepPage.skeleton';

function InstructionsContainer(): JSX.Element {
  const isMobile = useIsMobile();

  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.instructionsPage.instructionsContainer',
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="4"
      justifyContent="flex-start"
    >
      <Box>
        <Title as={isMobile ? 'h4' : 'h2'}>{t('title')}</Title>
      </Box>
      <Box display="flex" flexDirection="column" gap="4">
        <IconText icon={<DocumentIcon />} text={t('itemDocument')} />

        <IconText icon={<UserIcon />} text={t('itemSelfie')} />

        <IconText icon={<UploadIcon />} text={t('itemUpload')} />
      </Box>
    </Box>
  );
}

type IdentityValidationIntroductionStepPageProps =
  BankingIdentityValidationPageProps & {
    isLoading?: boolean;
    recipientInfo?: RecipientInfo;
  };

function IntroductionStepPageComponent({
  nextStep = () => {},
  previousStep = () => {},
  recipientInfo,
}: IdentityValidationIntroductionStepPageProps): JSX.Element | null {
  const [error, setError] = useState(false);
  const { t: navT } = useTranslationWithPrefix('navigation');
  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.instructionsPage',
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });
  }, [error, navT]);

  const refreshOnError = () => {
    setError(false);
  };

  const goToNextStep = () => {
    if (!recipientInfo) return;
    trackNavigation(recipientInfo.recipientType, 'introduction');
    nextStep();
  };

  return (
    <BasicLayout
      title={t('title')}
      subtitle={t('subtitle')}
      error={error}
      refreshHandler={refreshOnError}
    >
      <Box
        data-testid="introduction-step-page"
        backgroundColor={isMobile ? 'neutral-background' : undefined}
        padding="none"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box height="400px" maxWidth="1200px">
          <Box
            id="instructions"
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems="center"
            height="100%"
          >
            <Box
              padding="4"
              flexDirection="column"
              alignItems="flex-start"
              marginX={{ xl: '4', lg: '4', md: '4', xs: 'none' }}
            >
              <img
                alt={t('title')}
                src={'/assets/img/identity-validation.png'}
                width="100%"
              />
            </Box>

            <Box display="flex" gap="4" flexDirection="column">
              <InstructionsContainer />
              <FooterButtons
                primaryButtonLabel={t('buttons.primary')}
                primaryButtonHandler={goToNextStep}
                secondaryButtonLabel={t('buttons.secondary')}
                secondaryButtonHandler={previousStep}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </BasicLayout>
  );
}

function IntroductionStepPage({
  isLoading = false,
  ...props
}: IdentityValidationIntroductionStepPageProps): JSX.Element {
  if (isLoading) {
    return <IntroductionStepPageSkeleton />;
  }

  return <IntroductionStepPageComponent {...props} />;
}

export default IntroductionStepPage;
