import React from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { TransactionStatusType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';

interface WalletMethodProps {
  status?: TransactionStatusType;
}

export default function WalletMethod({ status }: Readonly<WalletMethodProps>) {
  const { t } = useTranslationWithPrefix(
    'transactionDetails.paymentMethods.wallet',
  );

  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Box display="inline-flex" alignItems="flex-start" gridGap="1-5">
        <Text fontWeight="bold">{t('description')}</Text>
      </Box>
      {status === 'pending' && <Text>{t('expiresAt')}</Text>}
      {status === 'failed' && <Text>{t('failed')}</Text>}
      {status === 'expired' && <Text>{t('expired')}</Text>}
    </Box>
  );
}
