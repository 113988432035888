import { InstallmentV2Type } from 'commons/types';
import { SelectedInstallments } from 'domains/Installments/useInstallmentsV2';
import { toSnake } from 'ts-case-convert';

export const isEnabledIn = (
  installmentQty: number,
  installmentList?: InstallmentV2Type[],
): boolean => {
  return (
    installmentList?.some(
      (item) => item.quantity == installmentQty && item.enabled,
    ) || false
  );
};

export const couldBeEnabledIn = (
  installmentName: string,
  installmentQty: number,
  installments?: SelectedInstallments,
): boolean => {
  if (!installments) return true;

  const installmentTypes = Object.entries(installments);

  const isEnabledInOtherInstallmentType = installmentTypes.some(
    ([name, list]: [string, InstallmentV2Type[]]) =>
      name !== installmentName &&
      name !== toSnake(installmentName) &&
      isEnabledIn(installmentQty, list),
  );

  return !isEnabledInOtherInstallmentType;
};

export const checkInstallment = (
  name: string,
  quantity: number,
  enabled: boolean,
  installmentList: InstallmentV2Type[],
  setInstallments: (installments: SelectedInstallments) => void,
  installments?: SelectedInstallments,
) => {
  if (installments) {
    const index = installmentList.findIndex(
      (installment) => installment.quantity === quantity,
    );
    if (index >= 0) {
      const updatedInstallment = {
        ...installmentList[index],
        enabled,
      };
      const newInstallments = [...installmentList];
      newInstallments[index] = updatedInstallment;
      setInstallments({
        ...installments,
        [name]: newInstallments,
      });
    }
  }
};
