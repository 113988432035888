import React from 'react';
import { Box, Card, Title } from '@nimbus-ds/components';
import { ErrorMessage } from 'commons/components';
import { useBankAccount } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import BankAccount from '../BankAccount';
import FiscalDocument from '../FiscalDocument';
import PersonalDataSkeleton from '../PersonalDataSkeleton';

function PersonalDataMexico() {
  const { t } = useTranslationWithPrefix('configuration.personalData');
  const bankAccount = useBankAccount();

  if (bankAccount.isLoading) {
    return <PersonalDataSkeleton />;
  }
  if (bankAccount.isError)
    return (
      <Card>
        <Card.Header>
          <Title as="h4">{t('cardTitle')}</Title>
        </Card.Header>
        <ErrorMessage />
      </Card>
    );

  const bankName = bankAccount.data.bankName;
  const beneficiaryName = bankAccount.data.holderName;
  const beneficiaryAccountNumber = bankAccount.data.accountNumber;
  const isRegistered = bankAccount.data.isRegistered;
  const fiscalNumber = bankAccount.data.fiscalDocument;

  return (
    <Card>
      <Card.Header>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title as="h4">{t('cardTitle')}</Title>
        </Box>
      </Card.Header>
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="6">
        <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
          <FiscalDocument fiscalNumber={fiscalNumber} />
          <BankAccount
            isRegistered={isRegistered}
            bankName={bankName}
            beneficiaryName={beneficiaryName}
            beneficiaryAccountNumber={beneficiaryAccountNumber}
          />
        </Box>
      </Box>
    </Card>
  );
}

PersonalDataMexico.displayName = 'PersonalDataMexico';

export default PersonalDataMexico;
