import React from 'react';

export type StepNavigatorContextProps<D = any> = {
  previousStep: () => void;
  nextStep: () => void;
  isFirstStep: boolean;
  isLastStep: boolean;
  data?: D;
};

export type BeforeMountStepsType<Data, ChildrenProps> = {
  (params: {
    steps: React.ReactElement<ChildrenProps>[];
    data: Data;
  }): React.ReactElement<ChildrenProps>[];
};

export const StepNavigatorContext =
  React.createContext<StepNavigatorContextProps | null>(null);

interface DataWizardProps<Data, ChildrenProps> {
  data?: Data;
  children: Array<React.ReactElement<ChildrenProps> | null> | null;
  onFirstStepCancel: () => void;
  onLastStepComplete: () => void;
  beforeMountSteps?: BeforeMountStepsType<Data, ChildrenProps>;
}

function StepNavigator<Data, ChildrenProps>({
  children,
  data,
  beforeMountSteps,
  onFirstStepCancel,
  onLastStepComplete,
}: DataWizardProps<Data, ChildrenProps>) {
  const [currentStep, setCurrentStep] = React.useState(0);

  const validSteps = React.Children.toArray(children).reduce<
    React.ReactElement<ChildrenProps>[]
  >((accumulatedSteps, child) => {
    if (React.isValidElement<ChildrenProps>(child)) {
      accumulatedSteps.push(child);
    }

    return accumulatedSteps;
  }, []);

  const hasBeforeMountStepsHook = typeof beforeMountSteps === 'function';

  const mountedSteps = hasBeforeMountStepsHook
    ? beforeMountSteps({ steps: validSteps, data: data as Data })
    : validSteps;

  const totalSteps = mountedSteps.length;
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === totalSteps - 1;
  const safeCurrentStep = Math.min(currentStep, totalSteps - 1);

  const previousStep = React.useCallback(() => {
    if (isFirstStep) {
      onFirstStepCancel();
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  }, [isFirstStep, onFirstStepCancel]);

  const nextStep = React.useCallback(() => {
    if (isLastStep) {
      onLastStepComplete();
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  }, [isLastStep, onLastStepComplete]);

  if (totalSteps === 0) {
    onLastStepComplete();
    return null;
  }

  return (
    <StepNavigatorContext.Provider
      value={{
        previousStep,
        nextStep,
        isFirstStep,
        isLastStep,
        data,
      }}
    >
      {mountedSteps[safeCurrentStep]}
    </StepNavigatorContext.Provider>
  );
}

export { StepNavigator };
