import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Text } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useTranslationWithPrefix } from 'commons/utils';

interface StatusMessage {
  appearance: 'success' | 'primary' | 'neutral' | 'danger' | 'warning';
  colorText: 'success-textLow' | 'warning-textLow';
  title: string;
  description: string | ((requestDate: string) => string);
  isBtnRequired: boolean;
}

interface InstallationStatusAlertProps {
  show: boolean;
  status: 'validationPending' | 'delayedValidation';
  requestDate: string | null;
}

function InstallationStatusAlert({
  status,
  show,
  requestDate,
}: InstallationStatusAlertProps) {
  const { t } = useTranslationWithPrefix(
    'landing.emptyState.onValidation.InstallationStatusAlert',
  );

  const getStatusMessages = (): Record<
    InstallationStatusAlertProps['status'],
    StatusMessage
  > => {
    return {
      validationPending: {
        appearance: 'warning',
        colorText: 'warning-textLow',
        title: t('validationPending.title'),
        description: t('validationPending.description'),
        isBtnRequired: false,
      },
      delayedValidation: {
        appearance: 'warning',
        colorText: 'warning-textLow',
        title: t('delayedValidation.title'),
        description: t('delayedValidation.description', {
          requestDate: requestDate,
        }),
        isBtnRequired: false,
      },
    };
  };

  const statusMessages = getStatusMessages();
  const { appearance, colorText, description, isBtnRequired, title } =
    statusMessages[status];
  const finalDescription =
    typeof description === 'function'
      ? description(requestDate || '')
      : description;

  const { push } = useHistory();

  const handleClick = () => {
    push(ROUTES.CONFIG);
  };

  return (
    <Alert appearance={appearance} show={show}>
      <Text fontWeight="bold" color={colorText}>
        {title}
      </Text>
      <Text>{finalDescription}</Text>
      {isBtnRequired && (
        <Button appearance="primary" onClick={handleClick}>
          {t('primaryBtn')}
        </Button>
      )}
    </Alert>
  );
}

export default InstallationStatusAlert;
