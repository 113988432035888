import React from 'react';
import { Box } from '@nimbus-ds/components';
import IconText from '../../IconText';

type Instruction = {
  icon: JSX.Element;
  text: string;
};

type InstructionListProps = {
  readonly instructions: Instruction[];
};

function InstructionList({ instructions }: InstructionListProps): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" gap="4">
      {instructions.map((instruction) => (
        <IconText
          key={instruction.text}
          icon={instruction.icon}
          text={instruction.text}
        />
      ))}
    </Box>
  );
}

export { InstructionList };
