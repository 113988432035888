import { TypeTag } from '../components/StatusTag';

const refundableStatuses: string[] = [TypeTag.Paid, TypeTag.PartiallyRefunded];

export const isRefundableStatus = (status: string) =>
  refundableStatuses.includes(status);

const statusesToShowFees: string[] = [
  TypeTag.Paid,
  TypeTag.PartiallyRefunded,
  TypeTag.Refunded,
  TypeTag.Contested,
];

export const showFeesForStatus = (status: string) =>
  statusesToShowFees.includes(status);

export const isPending = (status: string) => status === TypeTag.Pending;

const refundedStatuses: string[] = [
  TypeTag.Refunded,
  TypeTag.PartiallyRefunded,
];

export const isRefunded = (status: string) => refundedStatuses.includes(status);

export const isInProcess = (status: string) => status === TypeTag.Authorized;
