import React from 'react';
import { FileIcon } from '@nimbus-ds/icons';
import { useTranslationWithPrefix } from 'commons/utils';
import FaceIcon from '../icons/FaceIcon';
import UserIdentityIcon from '../icons/UserIdentityIcon';
import { InstructionList } from './Base/InstructionList';

function SelfieInstructionsList(): JSX.Element {
  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.selfieUploadPage.instructionsContainer',
  );

  const selfieInstructions = [
    {
      icon: <FaceIcon />,
      text: t('itemFace'),
    },
    {
      icon: <UserIdentityIcon />,
      text: t('itemSelfieWithDocument'),
    },
    {
      icon: <FileIcon />,
      text: t('itemFile'),
    },
  ];

  return <InstructionList instructions={selfieInstructions} />;
}

export { SelfieInstructionsList };
