import React from 'react';
import { EyeIcon, FileIcon, SunIcon } from '@nimbus-ds/icons';
import { useTranslationWithPrefix } from 'commons/utils';
import { InstructionList } from './Base/InstructionList';

function IdentificationDocumentInstructionsList(): JSX.Element {
  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.selectDocumentsPage.instructionsContainer',
  );

  const identificationDocumentInstructions = [
    {
      icon: <EyeIcon />,
      text: t('itemEye'),
    },
    {
      icon: <SunIcon />,
      text: t('itemSun'),
    },
    {
      icon: <FileIcon />,
      text: t('itemFile'),
    },
  ];

  return <InstructionList instructions={identificationDocumentInstructions} />;
}

export { IdentificationDocumentInstructionsList };
